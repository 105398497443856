export const SMALL = 's';
export const MEDIUM = 'm';
export const LARGE = 'l';
export const X_LARGE = 'xl';
export const ALL = [SMALL, MEDIUM, LARGE, X_LARGE];
export const DEFAULT_VIEWPORT = MEDIUM;

export const S_PX_MAX = 599;
export const M_PX_MAX = 1023;
export const L_PX_MAX = 1599;
