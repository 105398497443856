import { Injectable } from '@angular/core';
import { BaseRestService, ServiceProperties } from '@beneficity/shared/types';
import { Observable } from 'rxjs';
import { ExpLayerHttpClientService } from '@beneficity/shared/util';

@Injectable({
  providedIn: 'root',
})
export class SsoHelperService implements BaseRestService {
  serviceProperties: ServiceProperties;
  constructor(private readonly http: ExpLayerHttpClientService) {}

  fetch(partnerId: string, accessToken: string, requestBody): Observable<any> {
    this.serviceProperties = {
      url: `/digital/v1/members/single-sign-on/partner/${partnerId}`,
    };
    const headers: { [key: string]: string } = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
    return this.http.post(this.serviceProperties, requestBody, headers);
  }
}
