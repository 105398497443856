import { PlatformInfo } from '@beneficity/shared/types';
import { createAction, props } from '@ngrx/store';

export const loadDevice = createAction(
  '[Device] Load Device',
  props<{ appOrigin: string }>()
);

export const loadDeviceSuccess = createAction(
  '[Device] Load Device Success',
  props<{ device: PlatformInfo }>()
);

export const loadDeviceFailure = createAction(
  '[Device] Load Device Failure',
  props<{ error: any }>()
);
