import {
  AbstractControl,
  ValidationErrors,
  Validators as AngularValidators,
  FormGroup,
} from '@angular/forms';
import { FORM_ERROR_MESSAGES as ERRORS } from '@beneficity/shared/types';

const PATTERNS: any = {
  address: /^[0-9a-z#/%&]+[0-9a-z#/%&\s]*(?=^\s)*$/i, // Can only contain: A-Za-z0-9 _.#/&%
  city: /^[0-9a-z]+[0-9a-z\s]*(?=^\s)*$/i, // Can only contain: A-Z a-z 0-9 spaces
  state: /^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])?$/i, // eslint-disable-line max-len
  phone: /^(?:([1])[-\s/.]?)?(?:(?:[(]([2-9][0-9]{2})[)])|(?:([2-9][0-9]{2})))[-\s/.]?([0-9]{3})[-\s/.]?([0-9]{4})$/,
  numeric: /^[0-9]*$/,
  alphaNumeric: /^[a-z0-9]*$/i,
  allSpaces: /^\s+$/,
  lettersAndSpaces: /^[a-z\s]*$/i, //Can only contain : A-Z, a-z, spaces. not a required field
  lettersAposAndHyphens: /^[a-z'-]*$/i, //Can only contain : A-Z, a-z, apostrophes and hyphens. not a required field
  atleastOneLetterAndDigit: /^(?=.*[1-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
  ninePattern: /^(?=[a-zA-Z]{0,3}[0-9]{4,9}$)/,
  lettersOnly: /^[a-z']*$/i, // No leading whitespace symbols or special characters.
  email: /^[A-Za-z0-9]+(([._%+-]?)[A-Za-z0-9])*@[A-Za-z0-9]+([._%+-]?)+[A-Za-z0-9]+[.]([A-Za-z0-9]{2,})+$/,
};

export class AtlasValidators extends AngularValidators {
  private static _getError(
    code: string,
    message: string = ERRORS[code]
  ): ValidationError {
    return new ValidationError(code, message);
  }

  public static umi(c: AbstractControl): ValidationErrors | null {
    return AtlasValidators.numeric(c)
      ? AtlasValidators._getError('umi')
      : c.value && AngularValidators.minLength(12)(c)
      ? AtlasValidators._getError('umi', ERRORS['umi_length'])
      : null;
  }

  public static umiOrSsn(c: AbstractControl): ValidationErrors | null {
    return AtlasValidators.numeric(c)
      ? AtlasValidators._getError('umiOrSsn', ERRORS['umi'])
      : c.value && AngularValidators.minLength(9)(c)
      ? AtlasValidators._getError('umiOrSsn', ERRORS['umiOrSsn_length'])
      : null;
  }

  public static username(c: AbstractControl): ValidationError | null {
    return c.value &&
      (AngularValidators.minLength(6)(c) ||
        AngularValidators.maxLength(15)(c) ||
        AtlasValidators.alphaNumeric(c))
      ? AtlasValidators._getError('username')
      : null;
  }

  public static address(c: AbstractControl): ValidationErrors | null {
    return c.value && !PATTERNS.address.test(c.value)
      ? AtlasValidators._getError('address')
      : null;
  }

  public static city(c: AbstractControl): ValidationErrors | null {
    return c.value && !PATTERNS.city.test(c.value)
      ? AtlasValidators._getError('city')
      : null;
  }

  public static state(c: AbstractControl): ValidationErrors | null {
    return !PATTERNS.state.test(c.value)
      ? AtlasValidators._getError('state')
      : null;
  }

  // public static zipCode(c: AbstractControl): ValidationErrors | null {
  //   return c.value && PATTERNS.numeric.test(c.value) && c.value.length !== 5
  //     ? AtlasValidators._getError('zipCode')
  //     : null;
  // }
  //
  // public static fax(c: AbstractControl): ValidationErrors | null {
  //   return c.value && !PATTERNS.phone.test(c.value) ? AtlasValidators._getError('fax') : null;
  // }

  public static phone(c: AbstractControl): ValidationErrors | null {
    return c.value && !PATTERNS.phone.test(c.value)
      ? AtlasValidators._getError('phone')
      : null;
  }

  public static securityQuestionAnswer(
    c: AbstractControl
  ): ValidationErrors | null {
    return c.value && AngularValidators.maxLength(255)(c)
      ? AtlasValidators._getError('securityQuestionAnswer')
      : null;
  }

  public static numeric(c: AbstractControl): ValidationErrors | null {
    return c.value && !PATTERNS.numeric.test(c.value)
      ? AtlasValidators._getError('numeric')
      : null;
  }

  public static alphaNumeric(c: AbstractControl): ValidationErrors | null {
    return c.value && !PATTERNS.alphaNumeric.test(c.value)
      ? AtlasValidators._getError('alphaNumeric')
      : null;
  }

  // Form Group level validators
  // public static matchEmails(c: AbstractControl): ValidationErrors | null {
  //   // fields must be named "email" and "reEmail"
  //   return c.parent.get('reEmail').dirty && c.parent.get('email').value !== c.parent.get('reEmail').value
  //     ? AtlasValidators._getError('matchEmails')
  //     : null;
  // }

  // generalized matching validator
  public static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  // Over-riding Angular's Validators
  public static required(c: AbstractControl): ValidationErrors | null {
    return AngularValidators.required(c)
      ? AtlasValidators._getError('required')
      : null;
  }

  public static email(c: AbstractControl): ValidationErrors | null {
    return !PATTERNS.email.test(c.value)
      ? AtlasValidators._getError('email')
      : null;
  }

  public static date(c: AbstractControl): ValidationErrors | null {
    return AtlasValidators._getError('date', ERRORS['date']);
  }

  public static cancelDateMin(c: AbstractControl): ValidationErrors | null {
    return AtlasValidators._getError('cancelDateMin', ERRORS['cancelDateMin']);
  }

  // *** This is very important to QA ***
  // Show required message if input contains only spaces
  public static notAllSpaces(c: AbstractControl): ValidationErrors | null {
    return c.value && PATTERNS.allSpaces.test(c.value)
      ? AtlasValidators._getError('notAllSpaces')
      : null;
  }

  public static lettersAndSpaces(c: AbstractControl): ValidationErrors | null {
    return c.value && !PATTERNS.lettersAndSpaces.test(c.value)
      ? AtlasValidators._getError('lettersAndSpaces')
      : null;
  }

  public static lettersAposAndHyphens(
    c: AbstractControl
  ): ValidationErrors | null {
    return c.value && !PATTERNS.lettersAposAndHyphens.test(c.value)
      ? AtlasValidators._getError('lettersAposAndHyphens')
      : null;
  }

  // public static todayLessSixMonths(c: AbstractControl): ValidationError | null {
  //   const today = new Date();
  //   const dateLessSix = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
  //   return c.value && c.value < dateLessSix ? AtlasValidators._getError('todayLessDate') : null;
  // }
  //
  // public static futureDate(c: AbstractControl): ValidationError | null {
  //   const today = new Date();
  //   return c.value && c.value > today ? AtlasValidators._getError('futureDate') : null;
  // }

  public static atleastOneLetterAndDigit(
    c: AbstractControl
  ): ValidationErrors | null {
    return c.value && !PATTERNS.atleastOneLetterAndDigit.test(c.value)
      ? AtlasValidators._getError('atleastOneLetterAndDigit')
      : null;
  }

  public static ninePattern(c: AbstractControl): ValidationErrors | null {
    return c.value &&
      c.value.length === 9 &&
      !PATTERNS.ninePattern.test(c.value)
      ? AtlasValidators._getError('ninePattern')
      : null;
  }

  public static binMin(c: AbstractControl): ValidationErrors | null {
    return c.value && c.value.length > 0 && c.value.length !== 6
      ? AtlasValidators._getError('binMin', ERRORS['bin_length'])
      : null;
  }

  public static validateBIN(c: AbstractControl): ValidationErrors | null {
    return AtlasValidators._getError('validateBIN', ERRORS['bin_invalid']);
  }

  public static atleastOne(c: AbstractControl): ValidationErrors | null {
    return AtlasValidators._getError(
      'atleastOne',
      ERRORS['atleastOneMedEffDate']
    );
  }

  public static firstNameFieldValidator(
    c: AbstractControl
  ): ValidationErrors | null {
    return c.value && !PATTERNS.lettersOnly.test(c.value)
      ? AtlasValidators._getError(
          'policyHolderFirstNameNoSpaces',
          ERRORS['policyHolderFirstNameNoSpaces']
        )
      : null;
  }

  public static middleNameFieldValidator(
    c: AbstractControl
  ): ValidationErrors | null {
    try {
      return c.value && !PATTERNS.lettersOnly.test(c.value)
        ? AtlasValidators._getError(
            'middleNameField',
            ERRORS['middleNameField']
          )
        : null;
    } catch (err) {
      // console.log('Err: ', err);
    }
  }

  public static dobMin(c: AbstractControl): ValidationErrors | null {
    const minDate = new Date(1890, 0, 1);
    return c.value && c.value < minDate
      ? AtlasValidators._getError('dobMin', ERRORS['dobMin'])
      : null;
  }

  public static dobMax(c: AbstractControl): ValidationErrors | null {
    const yyyy = new Date().getFullYear();
    const mm = new Date().getMonth() + 1;
    const dd = new Date().getDate();
    const today = mm + '/' + dd + '/' + yyyy;

    const compare = new Date(today);
    return c.value && compare && c.value > compare
      ? AtlasValidators._getError('dobMax', ERRORS['dobMax'])
      : null;
  }

  public static otp(c: AbstractControl): ValidationErrors | null {
    return c.value &&
      (AngularValidators.minLength(6)(c) ||
        AngularValidators.maxLength(6)(c) ||
        AtlasValidators.numeric(c))
      ? AtlasValidators._getError('otp')
      : null;
  }

  public static todayLessSixMonths(c: AbstractControl): ValidationError | null {
    const today = new Date();
    const dateLessSix = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
    return c.value && c.value < dateLessSix ? AtlasValidators._getError('todayLessDate') : null;
  }

  public static futureDate(c: AbstractControl): ValidationError | null {
    const today = new Date();
    return c.value && c.value > today ? AtlasValidators._getError('futureDate') : null;
  }

}

class ValidationError {
  [key: string]: ValidationErrorContent;

  constructor(code: string, message: string) {
    this[code] = new ValidationErrorContent(false, message);
  }
}

class ValidationErrorContent {
  valid: boolean;
  message: string;

  constructor(valid: boolean, message: string) {
    this.valid = valid;
    this.message = message;
  }
}
