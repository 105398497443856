/**
 * Interface for the 'firebase' data
 */

export interface FirebaseState {
  fireBase: FireBaseInfo;
}

export const initialState: FirebaseState = {
  fireBase: null,
};

export interface FireBaseInfo {
  isAuthenticated: boolean;
  error?: any | null;
  correlationId?;
}
