export interface PlatformInfo {
  platform: string;
  platformType: string;
  appId: string;
  title: string;
}

export enum PLATFORMS {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web',
  MOBILE = 'mobile',
}

export type APPID = PLATFORMS.MOBILE | PLATFORMS.WEB;
