export const environment = {
  production: true,
  disableLogs: true,
  baseRegion: 'www',
  productName: 'myhighmark',
  cdn: {
    project: 'https://cdn.highmark.com/web/',
    base: 'https://cdn.highmark.com/',
  },
  serviceDomains: {
    dp: 'services.highmark.com',
    oam: 'oauth.hmhs.com',
    login: 'auth.highmark.com',
    cdsso: 'cdsso.highmark.com',
    gcp: 'prod.api.highmark.com',
    axway: 'api.hmhs.com',
    protocol: 'https://',
    experienceLayer: 'api.highmark.com',
  },
  oauth2Config: {
    env: 'auth',
    otp_username: 'appmfarest01',
    otp_password: 's4ndb0x1',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCGvyjSd-MibxYgChnhwUkkSU-8Y-cp7TY',
    authDomain: 'hhp-diginn-api-prod-243413.firebaseapp.com',
    databaseURL: 'https://hhp-diginn-api-prod-243413.firebaseio.com',
    projectId: 'hhp-diginn-api-prod-243413',
    storageBucket: 'hhp-diginn-api-prod-243413.appspot.com',
    messagingSenderId: '397796981812',
    appId: '1:397796981812:web:183f7d694bf5111de39e61',
    measurementId: 'G-ZLTJGFPCWM',
  },
  platforms: [
    {
      domain: 'https://run-usc1-p-iel-webviews-testapp-q6ns4kpo4q-uc.a.run.app',
      platform: 'web',
      appId: 'beneficity',
      title: 'Beneficity',
    },
    {
      domain: 'https://myhighmarkonline.com',
      platform: 'web',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://myhighmark.com',
      platform: 'web',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://member.myhighmark.com',
      platform: 'web',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://dev.member.myhighmark.com',
      platform: 'web',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://ios.myhighmarkonline.com',
      platform: 'ios',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://ios.myhighmark.com',
      platform: 'ios',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://android.myhighmarkonline.com',
      platform: 'android',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://android.myhighmark.com',
      platform: 'android',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://beneficity.com',
      platform: 'web',
      appId: 'beneficity',
      title: 'Beneficity',
    },
    {
      domain: 'https://member.beneficity.com',
      platform: 'web',
      appId: 'beneficity',
      title: 'Beneficity',
    },
    {
      domain: 'https://dev.member.beneficity.com',
      platform: 'web',
      appId: 'beneficity',
      title: 'Beneficity',
    },
    {
      domain: 'https://ios.beneficity.com',
      platform: 'ios',
      appId: 'beneficity',
      title: 'Beneficity',
    },
    {
      domain: 'https://android.beneficity.com',
      platform: 'android',
      appId: 'beneficity',
      title: 'Beneficity',
    },
    {
      domain: 'https://dev.beneficity.com',
      platform: 'web',
      appId: 'beneficity',
      title: 'Beneficity',
    },
    {
      domain: 'https://dev.myhighmarkonline.com',
      platform: 'web',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://dev.myhighmark.com',
      platform: 'web',
      appId: 'iel',
      title: 'My Highmark',
    },
    {
      domain: 'https://csportalutilities.hmhs.com',
      platform: 'web',
      appId: 'csportal',
      title: 'csportal',
    },
    // {
    //   domain: 'https://allmyhealth.com',
    //   platform: 'web',
    //   appId: 'amh',
    //   title: 'AllMyHealth',
    // },
    // {
    //   domain: 'https://ios.allmyhealth.com',
    //   platform: 'ios',
    //   appId: 'amh',
    //   title: 'AllMyHealth',
    // },
    // {
    //   domain: 'https://android.allmyhealth.com',
    //   platform: 'android',
    //   appId: 'amh',
    //   title: 'AllMyHealth',
    // },
  ],
  redirectWhitelist: [
    'https://dev.myhighmarkonline.com/sign-in',
    'https://dev.myhighmark.com/sign-in',
    'https://dev.beneficity.com/sign-in',
    'https://beneficity.com/',
    'https://beneficity.com/sign-in',
    'https://member.beneficity.com/',
    'https://member.beneficity.com/sign-in',
    'https://dev.member.beneficity.com/',
    'https://dev.member.beneficity.com/sign-in',
    'https://myhighmarkonline.com/sign-in',
    'https://myhighmark.com/',
    'https://myhighmark.com/sign-in',
    'https://member.myhighmark.com/',
    'https://member.myhighmark.com/sign-in',
    'https://dev.member.myhighmark.com/',
    'https://dev.member.myhighmark.com/sign-in',
    'https://allmyhealth.com/sign-in',
    'https://enroll.lark.com/',
    'league://accounts.beneficity.com/android/com.highmark.beneficity/callback',
    'com.highmark.beneficity://accounts.beneficity.com/ios/com.highmark.beneficity/callback',
    'league://accounts.myhighmarkonline.com/android/com.highmark.myhighmark/callback',
    'league://accounts.myhighmark.com/android/com.highmark.myhighmark/callback',
    'com.highmark.myhighmark://accounts.myhighmarkonline.com/ios/com.highmark.myhighmark/callback',
    'com.highmark.myhighmark://accounts.myhighmark.com/ios/com.highmark.myhighmark/callback',
    'life.league.HighmarkBeneficity://accounts.beneficity.com/ios/life.league.HighmarkBeneficity/callback',
    'league://accounts.beneficity.com/android/life.league.beneficity/callback',
    'life.league.highmark-iel://accounts.myhighmarkonline.com/ios/life.league.highmark-iel/callback',
    'life.league.highmark-iel://accounts.myhighmark.com/ios/life.league.highmark-iel/callback',
    'league://accounts.myhighmarkonline.com/android/life.league.iel/callback',
    'league://accounts.myhighmark.com/android/life.league.iel/callback',
  ],
  ssoConfig: {
    expressScriptUrl: `MEDCO`,
    //larkUrl:'https://enroll.staging.lark.com/hmsso/start?utm_source=hm&utm_medium=partner_sso',
    larkUrl: '',
    hmLarkUrl:
      'https://enroll.lark.com/hmsso?utm_source=hm&utm_medium=partner_sso',
    beneLarkUrl:
      'https://enroll.lark.com/beneficitysso?utm_source=hm&utm_medium=partner_sso',
    amwellProviderId: 'https://Well360VirtualHealth.com/',
    amwellRegisterUrl: 'https://well360virtualhealth.com',
    amwellConvergeUrl: '',
    springHealthUrl: '',
    hmSpringHealthUrl:
      'https://auth.highmark.com/oauth2/rest/authz?domain=SpringHealth&response_type=code' +
      '&client_id=6b347a3-0475-6713-8456475624859496f4a39385941513d3d&scope=resource.READ openid' +
      '&redirect_uri=https://auth.api.springhealth.com/oauth/sso/highmark',
    beneSpringHealthUrl:
      'https://auth.highmark.com/oauth2/rest/authz?domain=SpringHealthBeneficity&response_type=code' +
      '&client_id=3064416-c724-e456-64551325165386b342f46783078513d3d&scope=resource.READ openid' +
      '&redirect_uri=https://auth.api.springhealth.com/oauth/sso/beneficity',
    kyruusProviderId: 'https://findcare.app/auth/realms/Highmark',
    hmFitOnUrl:
      'https://auth.highmark.com/oauth2/rest/authz?domain=FitOn&response_type=code' +
      '&client_id=3059683-1646-14f6-66676476e6f4f6f554d6f30736b513d3d&scope=resource.READ openid' +
      '&redirect_uri=https://web.fitonapp.com/sso?provider=highmark',
  },
  mtmAppId: 'IEL',
  smsTextTermsUrl: 'https://www.highmark.com/hmk2/texting.shtml',
};
