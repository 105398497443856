import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';
import { take } from 'rxjs/operators';

import * as AuthenticationActions from './authentication.actions';
import * as AuthenticationSelectors from './authentication.selectors';
import { CookieList } from '@beneficity/authentication/types';

@Injectable()
export class AuthenticationFacade {
  isAuthenticated$ = this.store.pipe(
    select(AuthenticationSelectors.getIsAuthenticated)
  );
  authError$ = this.store.pipe(select(AuthenticationSelectors.getAuthError));
  user$ = this.store.pipe(select(AuthenticationSelectors.getAuthUser));
  origin$ = this.store.pipe(select(AuthenticationSelectors.getAuthOrigin));
  cookies$ = this.store.pipe(select(AuthenticationSelectors.getCookies));
  getMbrClientNumber$ = this.store.pipe(
    select(AuthenticationSelectors.getMbrClientNumber)
  );

  accessToken$ = this.store.pipe(
    select(AuthenticationSelectors.getAccessToken)
  );
  oamRefreshAttempted$ = this.store.pipe(
    select(AuthenticationSelectors.getOamRefreshAttempted)
  );

  logoutAttempted$ = this.store.pipe(
    select(AuthenticationSelectors.getLogoutAttempted)
  );
  isSalesDemo$ = this.store.pipe(
    select(AuthenticationSelectors.getIsSalesDemo)
  );

  getUbk(): number {
    let a = null;
    this.user$.pipe(take(1)).subscribe((y) => (a = y?.ubk));
    return a;
  }

  getCookieList(): CookieList {
    let a = null;
    this.cookies$.pipe(take(1)).subscribe((y) => (a = y));
    return a;
  }

  getAuthDomain(): string {
    let a = null;
    this.origin$.pipe(take(1)).subscribe((y) => (a = y));
    return a;
  }

  getMbrClientNumber(): string {
    let a = null;
    this.getMbrClientNumber$.pipe(take(1)).subscribe((y) => (a = y));
    return a;
  }

  getAccessToken(): string {
    let a = null;
    this.accessToken$.pipe(take(1)).subscribe((y) => (a = y));
    return a;
  }

  constructor(private store: Store) {}

  private dispatch(action: Action) {
    this.store.dispatch(action);
  }

  authenticate(
    accessToken: string,
    origin: string,
    defaultOverrideValues?: Record<string, string>
  ): void {
    this.dispatch(
      AuthenticationActions.authenticate({
        accessToken,
        origin,
        defaultOverrideValues,
      })
    );
  }

  logout(domain: string): void {
    this.dispatch(AuthenticationActions.logout({ domain }));
  }

  updateCookies(cookies: Record<string, string>) {
    this.dispatch(AuthenticationActions.updateCookies({ cookies }));
  }

  public oamRefresh() {
    this.store.dispatch(AuthenticationActions.oamSessionRefresh());
  }
}
