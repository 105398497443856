import {
  CookieList,
  IAuthenticator,
  IUser,
  UpdateEnrollmentStatus,
} from '@beneficity/authentication/types';
import { LOADING_STATUS } from '@beneficity/shared/types';

export interface AuthState {
  origin: string;
  isAuthenticated: boolean;
  accessToken?: string;
  attributes?: Record<string, unknown>;
  user?: IUser;
  error?: any | null;
  oamRefreshAttempted: boolean;
  logoutAttempted?: boolean;
  cookies: CookieList;
}

export const initialState: AuthState = {
  origin: '',
  isAuthenticated: false,
  accessToken: undefined,
  user: undefined,
  error: undefined,
  oamRefreshAttempted: false,
  logoutAttempted: false,
  cookies: undefined,
};
