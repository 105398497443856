import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { DeviceFacade } from '@beneficity/shared/data-access';
import { inFrame } from '@beneficity/shared/util';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'beneficity-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public loading = true;
  public addExtraHeight;
  constructor(
    private titleService: Title,
    private readonly deviceService: DeviceFacade,
    private router: Router
  ) {
    // Extra Height only needed for new window implementations such as SSO
    this.addExtraHeight = !inFrame();
    this.deviceService.deviceInfo$
      .pipe(
        filter((info) => !!info),
        take(1),
        tap((info) => {
          this.titleService.setTitle(info?.title);
        })
      )
      .subscribe();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
        tap((x) => (this.loading = false))
      )
      .subscribe();
  }
}
