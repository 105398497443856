import jwt_decode from 'jwt-decode';

export function getDecodedAccessToken(token: string): any {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}

/**
 * Base64 decode the a token. Conditionally extract cookie value.
 * @param token
 * @param cookieName
 * @public
 */
export function decodeToken(token: string, cookieName?: string): string {
  let decodedToken = null;
  if (token) {
    try {
      decodedToken = atob(token);
      if (cookieName?.length > 0) {
        decodedToken = decodedToken.substring(
          cookieName.length + 1,
          decodedToken.indexOf(';')
        );
      }
    } catch (e) {}
  }
  return decodedToken;
}

/**
 * Given a cookie string, parse out the value
 * @param cookie
 * @public
 */
export function parseCookieValue(cookie: string): string {
  const parts = cookie.split(';');
  const values = parts[0].split('=');
  return values[1];
}
