import { ComponentFactory, InjectionToken, TemplateRef } from '@angular/core';
import { ICustomAlertComponent } from './custom-alert-component';

export class CustomAlertModal {
  type?: string; /* error, info, warning, success */
  title?: string;
  heading?: string;
  message?: string;
  template?: TemplateRef<any>;
  buttons?: Array<CustomAlertButton>;
  headingStyle?: string;
  componentFactory?: ComponentFactory<ICustomAlertComponent>;
  input?: Array<CustomAlertInput>;
}

export class CustomAlertButton {
  constructor(
    public text: string = 'CLOSE',
    public callback?: (...args: any[]) => any,
    public cssClass?: string,
    public disabled?: boolean
  ) {}
}

export class CustomAlertInput {
  constructor(
    public name: string,
    public placeholder: string = 'password',
    public type: string
  ) {}
}

export interface Message {
  comment?: string;
  heading?: string;
  message: string;
}

export interface MessageConfigs {
  success: { [key: string]: Message };
  info: { [key: string]: Message };
  warning: { [key: string]: Message };
  error: { [key: string]: Message };
}
export const MESSAGE_TOKEN = new InjectionToken<MessageConfigs>(
  'Message Token For Custom Alert'
);
