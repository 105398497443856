import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import * as FirebaseActions from './firebase.actions';
import { map, switchMap } from 'rxjs/operators';
import {
  authenticateSuccess,
  logout,
} from '@beneficity/authentication/data-access';
import { of } from 'rxjs';
import { FirebaseAuthenticationService } from '../services/firebase-authentication.service';

@Injectable()
export class FirebaseEffects {
  /**
   * AUTH Actions kickoff firebase login and logout
   */
  authSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticateSuccess),
      switchMap((action) => {
        const sideEffects: any[] = [
          FirebaseActions.firebaseLogin({
            accessToken: action?.accessToken,
            attributes: action?.attributes,
            brand: action?.brand,
          }),
        ];
        return sideEffects;
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      switchMap((_) => {
        const sideEffects: any[] = [FirebaseActions.firebaseLogout()];
        return sideEffects;
      })
    )
  );

  /**
   * FIREBASE auth Effects
   */
  firebaseLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FirebaseActions.firebaseLogin),
      fetch({
        run: (action) => {
          return this.fbAuthService
            .loginFirebase(
              action?.attributes,
              action?.accessToken,
              action?.brand
            )
            .pipe(
              map((tokenResp) =>
                FirebaseActions.firebaseLoginSuccess({
                  correlationId: tokenResp?.correlationId,
                })
              )
            );
        },
        onError: (error) => {
          return of(FirebaseActions.firebaseLoginFailure({ error }));
        },
      })
    );
  });

  firebaseLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirebaseActions.firebaseLogout),
      fetch({
        run: () => {
          return this.fbAuthService
            .logoutFirebase()
            .pipe(map(() => FirebaseActions.firebaseLogoutSuccess()));
        },
        onError: (error) => {
          return of(FirebaseActions.firebaseLogoutFailure({ error }));
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private readonly fbAuthService: FirebaseAuthenticationService
  ) {}
}
