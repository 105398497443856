import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTHENTICATION_FEATURE_KEY } from './authentication.reducer';
import { AuthState } from './authentication.models';
import { IUser } from '@beneficity/authentication/types';

export const getAuthState = createFeatureSelector(AUTHENTICATION_FEATURE_KEY);

export const getAuthUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);

export const getAuthError = createSelector(
  getAuthState,
  (state: AuthState) => state.error
);

export const getAuthOrigin = createSelector(
  getAuthState,
  (state: AuthState) => state.origin
);

export const getAccessToken = createSelector(
  getAuthState,
  (state: AuthState) => state.accessToken
);

export const getIsAuthenticated = createSelector(
  getAuthState,
  (state: AuthState) => state.isAuthenticated
);

export const getLogoutAttempted = createSelector(
  getAuthState,
  (state: AuthState) => state.logoutAttempted
);

export const getOamRefreshAttempted = createSelector(
  getAuthState,
  (state: AuthState) => state.oamRefreshAttempted
);

export const getCookies = createSelector(
  getAuthState,
  (state: AuthState) => state.cookies
);

export const getMbrClientNumber = createSelector(
  getAuthUser,
  (state: IUser) => state.mbrClientNumber
);

export const getIsSalesDemo = createSelector(
  getAuthUser,
  (state: IUser) => state.isSalesDemo
);
