export const FORM_ERROR_MESSAGES: any = {
  umi: 'Enter numbers only. No letters, spaces or hyphens.',
  umi_length: "Check that you've entered all numbers in your member ID.",
  umiOrSsn_length: 'Member ID/SSN should be a minimum of 9 digits.',
  umiOrSsn: 'Enter numbers only. No letters, spaces or hyphens.',
  username: 'Username must be between 6-15 letters and numbers.',
  passwordRequirements: 'Password does not meet requirements.',
  passwordMismatch: 'Passwords must match.',
  address:
    'Enter an address using letters, numbers, and symbols (we only accept#, /, &, %).',
  city: 'Enter a city using letters, numbers, and spaces.',
  state: 'Invalid state abbreviation.',
  zipCode: 'Enter a valid 5-digit ZIP Code.',
  phone: 'Enter a valid phone number.',
  fax: 'Enter a valid fax number.',
  date: 'Please enter a valid date',
  numeric: 'Must be numeric.',
  alphaNumeric: 'Must only contain letters and numbers.',
  securityQuestionAnswer: 'Your answer must be between 1 and 255 characters.',
  matchEmails: 'Email addresses must match.',
  email: 'Enter a valid email address.',
  required: 'Required field.',
  notAllSpaces: 'Required field.',
  termsAccepted: 'Terms must be accepted',
  dobMin: 'Please enter a valid date of birth',
  dobMax: 'Please enter a valid date of birth',
  termsAcceptedIsDirty:
    'You cannot continue unless you have read and you accept the terms of conditions. Please check the box to let us know you have.',
  lettersAndSpaces: 'Must only contain letters and spaces',
  lettersAposAndHyphens: 'Must only contain letters, apostrophes, and hyphens',
  policyHolderFirstName: 'First name may only contain letters and spaces',
  policyHolderFirstName_length: 'Must be 25 characters or less.',
  policyHolderLastName:
    'Last name may only contain letters, apostrophes, and hyphens',
  policyHolderLastName_length: 'Must be 35 characters or less.',
  policyHolderLastName_lastfourthspace:
    'Spaces cannot be entered after the fourth character',
  groupNumber: 'Group number may only contain letters and digits',
  groupNumber_length: 'Must be 30 characters or less.',
  insuranceCarrier_length: 'Must be 35 characters or less.',
  policyNumber_length: 'Must be 17 characters or less.',
  todayLessDate: 'Date cannot be more than 6 months in the past',
  pcn_length: 'Must be 10 characters or less.',
  bin_length: 'Must be exactly 6 characters.',
  bin_invalid: 'Invalid combination of PCN and BIN',
  cancelDateMin: 'Cancel date must come after Effective date.',
  endDateMin: 'End date must come after Start date.',
  medicareNumber: 'Must be between 9 and 13 letters and numbers with no spaces',
  atleastOneLetterAndDigit:
    'Must contain at least one letter and one digit greater than zero',
  medicareNumberPattern:
    'Medicare number of 9 characters should be 3 letters followed by 6 digits',
  futureDate: 'Date cannot be in the future',
  atleastOneMedEffDate: 'Medicare A or Medicare B must have an effective date',
  middleNameField: 'Your middle name can only contain letters',
  policyHolderFirstNameNoSpaces:
    'First name may only contain letters and spaces',
  billingIDNumeric: 'Enter numbers only. No letters, spaces or hyphens.',
  require: {
    username: 'Your username is required.',
    umiOrSsn: 'Member ID or SSN is required.',
    umi: 'Member ID is required.',
    password: 'This field is required.',
    firstName: 'Your first name is required.',
    lastName: 'Your last name is required.',
    email: 'Your email address is required.',
    phone: 'Phone number is required.',
    address: 'Address is required.',
    city: 'City is required.',
    state: 'State is required.',
    zipCode: 'Zip code is required.',
    securityQuestion: 'Your security question is required',
    securityAnswer: 'Your security answer is required.',
    dateOfBirth: 'Your date of birth is required.',
    verificationCode: 'Verification code is required',
    chatMessage: 'Please type a message',
    ebill: 'Billing ID is required.',
    healthPlan: 'Health plan is required.',
    GDPRConsent: 'You must agree with the above consent before continuing',
    messageTopic: 'Select a Subject.',
    questionsAndComments: 'Enter questions or comments.',
  },
  csr: {
    require: 'Username is required',
  },
  customerServiceDesktop: {
    healthPlanChangeNewMessageTopic: `Because you selected a different plan, you'll need to select a message topic again.
          Any information that you've previously entered will be saved.`,
  },
  otp: {
    phone: 'Enter the 6 digit numeric code sent to your phone.',
    email: 'Enter the 6 digit numeric code sent to your email.',
  },
};
