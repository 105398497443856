import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRestService, ServiceProperties } from '@beneficity/shared/types';
import { AxwayHttpClient } from '@beneficity/shared/util';

@Injectable()
export class MemberAccessibleService implements BaseRestService {
  serviceProperties: ServiceProperties;
  private readonly BRAND_UBK = 'brandubk';
  
  constructor(private readonly http: AxwayHttpClient) {
    this.serviceProperties = {
      url: '/d20mdr/secure/dig/v1/member-coverage/accessible',
    };
  }
  fetch(
    token: string,
    defaultOverrideValues?: Record<string, string>
  ): Observable<any> {
    const headers: { [key: string]: string } = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };

    // If the vendor supplies ubk & brand we provide them to accessible to get the appropriate coverage for that brand.
    const params: Record<string, string> = {};
    if (Boolean(defaultOverrideValues)) {
      for (const k of Object.keys(defaultOverrideValues)){
        if (k.toLowerCase() === this.BRAND_UBK && Boolean(defaultOverrideValues[k])) {
          params.ubk = defaultOverrideValues[k];
        }
      }  
    }
    
    return this.http.get(this.serviceProperties, params, headers);
  }
}
