import { Inject, Injectable } from '@angular/core';
import { StyleManagerService } from '@atlas/material';
import { LayoutService } from '@beneficity/shared/util';
import { LARGE, MEDIUM, SMALL, X_LARGE } from '@beneficity/shared/types';
import { CdnPipe } from '@atlas/ng-core';
import { AuthenticationFacade } from '@beneficity/authentication/data-access';
import { DeviceFacade } from '@beneficity/shared/data-access';
import { findParentWindow, postMessageToWindow } from '@beneficity/shared/util';
import { Router } from '@angular/router';
import { ENV_CONFIG, EnvConfig } from '@beneficity/environment/types';
import { IconService } from './icons.service';

@Injectable({
  providedIn: 'root',
})
export class AppLoadService {
  /**
   * Warning, do not inject Router, or a provider that has dependencies on Router.
   * APP_INITIALIZER happens before the router init.
   */
  constructor(
    private styleManager: StyleManagerService,
    private readonly layout: LayoutService,
    private readonly cdnPipe: CdnPipe,
    private readonly authFacade: AuthenticationFacade,
    private readonly deviceFacade: DeviceFacade,
    private readonly iconService: IconService,
    private router: Router,
    @Inject(ENV_CONFIG) private readonly envConfig: EnvConfig
  ) {}

  public static getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Notify all valid domains that the webview app is ready to receive messages via postMessage.
   * @private
   */
  private static notifyValidDomains(platforms: any) {
    const windows = findParentWindow();
    for (const origin of platforms) {
      if (windows?.opener && window?.opener != null) {
        postMessageToWindow({ ready: true }, window?.opener, origin?.domain);
      }
      if (windows?.parent && window?.parent != null) {
        postMessageToWindow({ ready: true }, window?.parent, origin?.domain);
      }
    }

    // Send an extra post message to our own window for mobile apps
    postMessageToWindow({ ready: true }, window, window?.location?.origin);
  }

  async initApp() {
    // Set parent event listener to handle auth handshake with opening application
    // 1. Listen for postMessage with auth token.
    // 2. Notify opener that the webview app is ready to receive postMessages
    // 3. Initiate auth token validation.
    this.parentEventListener();

    // Init UI resources
    this.iconService.registerIcons();
    this.loadTypography(this.layout.getSize());
  }

  private async loadTypography(size) {
    if (size === SMALL) {
      this.styleManager.removeStyle('typography');
    } else {
      const theme =
        size === MEDIUM
          ? 'medium'
          : size === LARGE || size === X_LARGE
          ? 'large'
          : '';
      this.styleManager.setStyle(
        'typography',
        this.cdnPipe.transform(`/assets/typography/_typography_${theme}.css`)
      );
      await this.timeout(0); // Add 0 second timeout to avoid content flicker
    }
  }

  private timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Use PostMessage due to same origin policy for iframe.
   * @private
   */
  private parentEventListener() {
    let tokenExchangeTimeout = null;

    // Reusable listener func that can be added & removed from the window
    const parentListener = (event) => {
      // IMPORTANT: check the origin of the data!
      const validDomain = this.envConfig?.platforms.find(
        (platform) => platform?.domain === event.origin
      );
      if (Boolean(validDomain)) {
        // The data was sent from our trusted site.
        if (event?.data?.accessToken?.length > 0) {
          clearTimeout(tokenExchangeTimeout);
          this.authFacade.authenticate(
            event?.data?.accessToken,
            event.origin,
            event.data?.defaultOverrideValues
          );
          this.deviceFacade.loadDeviceInfo(event.origin);
        }
      } else {
        return;
      }
    };

    // Set parent listener, and wait for token payload
    window.addEventListener('message', parentListener);

    // Now that we are listening for messages, notify all valid domains that we are ready.
    AppLoadService.notifyValidDomains(this.envConfig.platforms);

    // Begin timeout after notifying valid domain that the applicaiton is ready.
    // Applicable on all secured routes. Logout and ready pages are public.
    const path = window.location.pathname;
    if (path !== '/logout' && path !== '/cdssologout' && path !== '/ready') {
      tokenExchangeTimeout = setTimeout(() => {
        // In the event that we do not make a successful/timely token exchange with the window opener/parent
        // We will terminate the listener and  navigate the user to the generic error page.
        window.removeEventListener('message', parentListener);
        this.router.navigateByUrl('/error');
      }, 30000);
    }
  }
}
