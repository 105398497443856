import { Injectable } from '@angular/core';
import { AuthenticationFacade } from '@beneficity/authentication/data-access';
import { findParentWindow, postMessageToWindow } from '@beneficity/shared/util';

@Injectable({ providedIn: 'root' })
export class ParentMessageService {
  constructor(private readonly authFacade: AuthenticationFacade) {}

  public sumbit(data: Record<string, any>) {
    const windows = findParentWindow();
    const authDomain = this.authFacade.getAuthDomain();
    console.log('sending mfa complete message');
    if (windows?.opener && window?.opener != null) {
      postMessageToWindow(data, window?.opener, authDomain);
    }
    if (windows?.parent && window?.parent != null) {
      postMessageToWindow(data, window?.parent, authDomain);
    }
    // Send an extra post message to our own window for mobile apps
    postMessageToWindow(data, window, window?.location?.origin);
  }
}
