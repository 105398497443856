import { createReducer, on, Action } from '@ngrx/store';

import * as FirebaseActions from './firebase.actions';
import { initialState, FirebaseState } from './firebase.models';

export const FIREBASE_FEATURE_KEY = 'firebase';

export interface FirebasePartialState {
  readonly [FIREBASE_FEATURE_KEY]: FirebaseState;
}

const firebaseReducer = createReducer(
  initialState,
  on(FirebaseActions.firebaseLoginSuccess, (state, action) => ({
    ...state,
    fireBase: {
      isAuthenticated: true,
      correlationId: action.correlationId,
    },
  })),
  on(
    FirebaseActions.firebaseLoginFailure,
    FirebaseActions.firebaseLogoutFailure,
    (state, action) => ({
      ...state,
      fireBase: {
        isAuthenticated: false,
        correlationId: undefined,
        error: action.error,
      },
    })
  )
);

export function reducer(state: FirebaseState | undefined, action: Action) {
  return firebaseReducer(state, action);
}
