import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as DeviceActions from './device.actions';
import { catchError, map } from 'rxjs/operators';
import { PlatformInfo, PLATFORMS } from '@beneficity/shared/types';
import { of } from 'rxjs';
import { ENV_CONFIG, EnvConfig } from '@beneficity/environment/types';

@Injectable()
export class DeviceEffects {
  loadDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceActions.loadDevice),
      map((action) => {
        const origin: {
          appId: string;
          domain: string;
          platform: string;
          title: string;
        } = this.env.platforms.find(
          (validPlatform) => validPlatform.domain === action?.appOrigin
        );

        const device: PlatformInfo = {
          appId: origin.appId,
          title: origin.title,
          platform: origin.platform,
          platformType:
            origin?.platform === PLATFORMS.WEB
              ? PLATFORMS.WEB
              : PLATFORMS.MOBILE,
        };

        return DeviceActions.loadDeviceSuccess({ device });
      }),
      catchError((error) => of(DeviceActions.loadDeviceFailure({ error })))
    )
  );

  constructor(
    private actions$: Actions,
    @Inject(ENV_CONFIG) private readonly env: EnvConfig
  ) {}
}
