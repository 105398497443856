import { MessageConfigs } from '@beneficity/custom-alert/types';

export const messageConfig: MessageConfigs = {
  success: {
    generic: {
      comment: '',
      message: '',
    },
    contactInfoNewConfirmationCode: {
      comment: 'User has requested new confirmation code',
      heading: '',
      message:
        'Your request has been received. You will soon receive an email with the confirmation code.',
    },
    contactInfoSaved: {
      comment: 'User has updated his/her contact information',
      heading: '',
      message: 'Your contact information has been updated.',
    },
    contactPreferencesSaved: {
      comment: 'User has saved changes to his/her contact preferences',
      heading: '',
      message: 'Your preferences have been saved.',
    },
    contactPreferencesMobileNumberSaved: {
      comment: 'User has updated mobile number',
      heading: '',
      message: 'Your mobile number has been saved.',
    },
    contactPreferencesWorkNumberSaved: {
      comment: 'User has updated work number',
      heading: '',
      message: 'Your work number has been saved.',
    },
    contactPreferencesHomeNumberSaved: {
      comment: 'User has updated home number',
      heading: '',
      message: 'Your home number has been saved.',
    },
    savedPreferences: {
      comment: 'Saving user preferences',
      heading: '',
      message: 'Your changes have been saved.',
    },
    updatedPassword: {
      comment: 'User has updated password',
      heading: '',
      message: 'Your password has been updated.',
    },
    'CSD-SUBMITTAL-SUCCESS': {
      comment: '',
      heading: '',
      message:
        'Your message was submitted successfully. We will send you an email when you have a reply waiting in your message center.',
    },
  },
  info: {
    generic: {
      comment: '',
      message: '',
    },
    duplicateRegistration: {
      comment: "User's account is already registered",
      heading: "Oops!  Something's wrong.",
      message:
        'You have already registered!  To access your account, log in now.  Forgot your username or password?  ' +
        'We can help you recover your online access. Forgot your username or password?  ' +
        'Use the forgotten username and/or password options on the login page.',
    },
    featureNotAvailable: {
      comment: 'This new site is currently unavailable for you',
      message:
        '<b>You will now be redirected to the existing site.</b>' +
        '<p>This website is still under construction and not available in your area yet. ' +
        'For additional information, please refer to the site on the back of your member ID card.</p>',
    },
    quantumBoxout: {
      comment: 'custom boxout for quantum redirect',
      heading: '',
      message:
        'Your employer is now providing its employees & dependents with a new member portal experience! ' +
        'Please access and register on your new site. ' +
        'Going forward, you will no longer be accessing this website/app for your member healthcare portal needs.',
    },
    termsCondition: {
      comment: 'Terms & condition',
      heading: '',
      message:
        'You cannot save until you have read and accepted the terms and conditions. Please check the box to let us know you have.',
    },
    mfaUnenrollment: {
      comment: 'Multi-factor authentication will be unenrolled.',
      heading: 'Unenroll Multi-factor Authentication',
      message:
        'If you change your mobile number or personal email, you will be unenrolled in multi-factor authentication.' +
        'Click OK to proceed or CANCEL to return to previous page.',
    },
    formFileTypeNotAllowed: {
      comment: 'The user tries to upload unsupported file',
      heading: '',
      message: `<p>You uploaded a file type that is not supported by our system. Please try your upload again using one of these types:</p>
      <ul><li>.pdf</li><li>.doc</li><li>.png</li><li>.gif</li><li>.jpg</li></ul>`,
    },
    fileSizeExceed: {
      comment: 'The user tries to upload more than 5mb in total',
      heading: '',
      message:
        'Your attachments have exceeded the maximum number of megabytes permitted. Please try again.',
    },
    fileNumbers: {
      comment: 'The user tries to upload more than 3 files',
      heading: '',
      message: 'You may only attach 3 files. Please try again.',
    },
    fileTypeNotAllowed: {
      comment: 'The user tries to upload unsupported file',
      heading: '',
      message: `<p>You uploaded a file type that is not supported by our system. Please try your upload again using one of these types:</p>
      <ul><li>.pdf</li><li>.doc</li><li>.docx</li><li>.png</li><li>.xls</li><li>.xlsx</li><li>.jpg</li><li>.gif</li><li>.bmp</li>
      <li>.tif</li><li>.txt</li></ul>`,
    },
  },
  warning: {
    generic: {
      comment: '',
      message: '',
    },
    duplicatePasswordError: {
      comment: 'Duplicate Password.',
      message: 'New password must be different from current password.',
    },
    paperlessCommunicationPreferences: {
      comment: 'Ask user to enter valid personal email id',
      heading: 'Important!',
      message: 'You do not have a valid email address saved. Please add one.',
    },
  },
  error: {
    template: {
      comment: '',
      heading: '',
      message: '',
    },
    generic: {
      comment: 'Generic Error Message',
      heading: 'Oops! Something went wrong!',
      message: 'An error has occurred. Please try again.',
    },
    getMessages: {
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but we can't access your messages at this time. Please try again later.",
    },
    problemLoadingMessages: {
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but an error happened while loading your messages.  Please try again later.",
    },
    registrationGeneric: {
      comment: 'Generic registration error',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but an unexpected error happened.  Please try again later.",
    },
    registrationToLoginFailure: {
      comment: 'Registration to Login faulure',
      heading: 'Oops! Something went wrong!',
      message:
        "Your account was registered, but we can't seem to log you in right now. " +
        'Please try to log in by entering your username and password. ' +
        'If the problem continues, call the number on the back of your member ID card.',
    },
    'GEN-NOMATCHREG': {
      comment: 'Registration error',
      heading: 'Oops! Something went wrong!',
      message:
        'Please check that the information you entered matches exactly ' +
        'what is printed on your member ID card and then try again.',
    },
    'MIN-AGE': {
      comment: 'Registration error',
      heading: 'Oops! Something went wrong!',
      message:
        'For legal reasons, this app is not available to members younger than 18.',
    },
    'GEN-CONFCOMM*': {
      comment: 'Registration error',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but something went wrong with your registration. " +
        'Please call the number on the back of your member ID card and reference the error code: C-FLAG.',
    },
    'CP-SEARCH-FAILURE': {
      comment: 'Registration error',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but we're unable to register you at this time. " +
        'Please call the number on the back of your member ID card and reference the error code: CP-SEARCH-FAILURE.',
    },
    'DUP-LOGIN': {
      comment: 'Registration user error',
      heading: 'Oops! Something went wrong!',
      message:
        'That login ID is taken. Please enter a new login ID and try again.',
    },
    'CP-GEN-FAILURE': {
      comment: 'Registration user error',
      heading: 'Oops! Something went wrong!',
      message:
        'Your registration was not successful. ' +
        'Please try again later. ' +
        'Or, get help now by calling the number on the back of your member ' +
        'ID card and referencing the error code: CP-GENERAL.',
    },
    'UDP-LDAP': {
      comment: 'Registration user error',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but we are unable to register you at this time. " +
        'Please try again later. ' +
        'Or, get help now by calling the number on the back of your member ' +
        'ID card and referencing the error code: LDAP-UPATE.',
    },
    'UDP-CRMPP': {
      comment: 'Registration user error',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but we are unable to register you at this time. " +
        'Please try again later. ' +
        'Or, get help now by calling the number on the back of your member ' +
        'ID card and referencing the error code: UDP-CRMPP.',
    },
    securityQuestionService: {
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but an error happened while saving your security question. Please try again.",
    },
    securityQuestionLoading: {
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but we're unable to load your security question at this time. Please try again later.",
    },
    securityQuestionValidationAnswer: {
      heading: 'Oops! Something went wrong!',
      message: 'Please provide an answer to the question that you selected.',
    },
    securityQuestionValidationQuestion: {
      heading: 'Oops! Something went wrong!',
      message: 'Please select a security question from the list above.',
    },
    changePasswordService: {
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but we could not update your password at this time. Please try again later.",
    },
    passwordHistory: {
      heading:
        "It seems your updated password doesn't meet our security requirements.",
      message:
        "Don't worry. Just try again and remember not to use any previous passwords or include first or last names.",
    },
    registrationPasswordRule: {
      heading:
        "It seems your updated password doesn't meet our security requirements.",
      message:
        "Don't worry. Just try again and remember not to include first or last names.",
    },
    changePasswordComplexity: {
      heading: 'Oops! Something went wrong!',
      message: 'Please make sure that your password meets the requirements.',
    },
    changePasswordToSamePassword: {
      heading: 'Oops! Something went wrong!',
      message:
        'Your new password cannot be the same as your old password. Please enter a new password and try again.',
    },
    changePasswordMismatch: {
      heading: 'Oops! Something went wrong!',
      message:
        'The passwords that you entered do you not match.  Please try again. ',
    },
    changePasswordInvalidInput: {
      heading: 'Oops! Something went wrong!',
      message: 'Please make sure that your password meets the requirements.',
    },
    contactPrefsService: {
      heading: 'Oops! Something went wrong!',
      message: 'Your information could not be saved - please try again later.',
    },
    confirmationCodeIncorrect: {
      heading: 'Oops! Something went wrong!',
      message: 'You must enter the confirmation code sent to you by email.',
    },
    chipStepMismatch: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        "Having trouble creating your child's account?  Please call the number on the back of your member ID card.",
    },
    chipStepVerification: {
      comment: 'Chip - Cannot invalid data entered',
      heading: 'Oops! Something went wrong!',
      message:
        "Having trouble creating your child's account? " +
        'Please make sure that you entered your own first and last name as well as the ' +
        "last 4 digits of your child's social security number.",
    },
    chipDisclaimer: {
      comment: 'Chip - Disclaimer',
      heading: 'Oops! Something went wrong!',
      message: 'Please read and agree to the disclaimer before proceeding.',
    },
    chipStepService1: {
      comment: 'Chip - Problem with baseMessage in service response',
      heading: 'Oops! Something went wrong!',
      message: 'An error has occurred. Please try again later.',
    },
    chipStepService2: {
      comment: 'Chip - Problem with error body in service response data',
      heading: 'Oops! Something went wrong!',
      message: 'An error has occurred. Please try again later.',
    },
    'BOXED-OUT-UNDER-AGE': {
      comment: 'Registration error',
      heading: 'Oops! Something went wrong!',
      message:
        'For legal reasons, this app is not available to members younger than 18.',
    },
    loginGeneric: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        "We’re sorry, we're having trouble processing your request right now. " +
        'Please try again in a few minutes. ' +
        'Or, get help now by calling us at 1-877-298-3918 and referencing error code: GE001-PRD.  ',
    },
    accountLockout: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'For security reasons we have to suspend your online access. ' +
        'For help, call 1-877-298-3918 and reference code: LA002-SUSP.',
    },
    unacceptedTermsOfUse: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'Your account was created. Please accept the Terms of Use prior to logging in to this application.',
    },
    unacceptedTermsOfUseRegistration: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'Please accept the Terms of Use, so that we can create your account.',
    },
    'GEN-NORECCHMECS': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'Incorrect username or password. ' +
        'To protect your privacy, we have to lock your account after 3 attempts. ' +
        'Forgot your username or password?  Use the forgotten username and/or password options on the login page.',
    },
    'GEN-NOMATCH': {
      comment: 'Username and password mismatch',
      heading: 'Oops! Something went wrong!',
      message:
        'Incorrect username or password. ' +
        'To protect your privacy, we have to lock your account after 3 attempts. ' +
        'Forgot your username or password?  Use the forgotten username and/or password options on the login page.',
    },
    'USER-NOT-ON-FILE': {
      comment: 'Username does not exist',
      heading: 'Oops! Something went wrong!',
      message:
        'Incorrect username or password. ' +
        'To protect your privacy, we have to lock your account after 3 attempts. ' +
        'Forgot your username or password?  Use the forgotten username and/or password options on the login page.',
    },
    'GEN-SUSPEND': {
      comment: 'Account is suspended',
      heading: 'Oops! Something went wrong!',
      message:
        'For security reasons, we have to suspend your online access. ' +
        'Please go to Recover Online Access and follow the steps shown.',
    },
    'GEN-ACCOUNT-SUSPENDED': {
      comment: 'Account is suspended',
      heading: 'We have to suspend your online access.',
      message:
        'For security reasons we have to suspend your online access. ' +
        'For help, call 1-877-298-3918. Refer to code: LA002-SUSP.',
    },
    'GEN-NOACCESS': {
      comment: 'Canceled coverage over 365',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but you can't access this site because your health coverage ended over 365 days ago. " +
        'If you think this message is an error, call 1-877-298-3918 and reference code: ML000-NOCVG.',
    },
    OVER_CHIP14: {
      comment: '',
      heading:
        "We're sorry, but you can no longer access this account as a parent/guardian.",
      message:
        'Our records show that your dependent is now 14-years old. ' +
        'Your dependent needs to register and create a new username/password in order to access their account. ' +
        'If you have questions, call the number on the back of your ID card.',
    },
    CHIP14_GUARDIAN_VERIFICATION_FAIL: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'Please correct your information and try again. ' +
        "Make sure to enter your own first and last name. Enter the last 4 digits of your child's SSN.",
    },
    CHIP14_GUARDIAN_VERIFICATION_FAILED_MULTIPLE: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        "Having trouble creating your child's account? Please call the number on the back of your member ID card.",
    },
    NO_ACCESSIBLE_COVERAGE_EXCEPTION: {
      comment: 'Canceled coverage over 365',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but you can't access this site because your health coverage ended over 365 days ago. " +
        'If you think this message is an error, call 1-877-298-3918 and reference code: ML000-NOCVG.',
    },
    'GEN-CONFCOMM': {
      comment: 'Confidential communication',
      heading: 'Oops! Something went wrong!',
      message:
        'You can no longer access this account on our website. ' +
        'If you think this message is an error call 1-877-298-3918 and reference code ML001-CONF.',
    },
    'INVALID-LOGIN-BRANDCONFLICT': {
      comment: 'Branding redirect error',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but we don't recognize you. " +
        "To protect your privacy, we can't give you access to this site, at this time. " +
        'Please check to make sure you entered the correct web address.',
    },
    'ACC-ECS': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We’re sorry, but we’re having trouble processing your request right now. ' +
        'Please try again in a few minutes. ' +
        'If the problem continues, call 1-877-298-3918 and reference code: ML002-MBR.',
    },
    'ACC-ICIS': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We’re sorry, but we’re having trouble processing your request right now. ' +
        'Please try again in a few minutes. ' +
        'If the problem continues, call 1-877-298-3918 and reference code: ML003-GRP.',
    },
    'ACC-CRMPP': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We’re sorry, but we’re having trouble processing your request right now. ' +
        'Please try again in a few minutes. ' +
        'If the problem continues, call 1-877-298-3918 and reference code: ML007-MBRPROF.',
    },
    'ACC-CP': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We’re sorry, but we’re having trouble processing your request right now. ' +
        'Please try again in a few minutes. ' +
        'If the problem continues, call 1-877-298-3918 and reference code: ML004-CP.',
    },
    ERROR_ECID_NOMATCH_CP: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We’re sorry, but we’re having trouble processing your request right now. ' +
        'Please try again in a few minutes. ' +
        'If the problem continues, call 1-877-298-3918 and reference code: ML003-ECID.',
    },
    'EMPTY-PRODUCT-LIST': {
      heading: 'Oops! Something went wrong!',
      message:
        'You can no longer access this account. ' +
        'If you think this message is an error, call 1-877-298-3918 and reference code: ML005-TDL.',
    },
    ERROR_BAD_REQUEST: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'A problem happened while processing your request. ' +
        'Please try again in a few minutes.',
    },
    NO_BRAND_ACCESS: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We value your privacy. This request has been blocked and the attempted access has been logged.',
    },
    NO_INTRACOMPANY_ACCESS: {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We value your privacy. This request has been blocked and the attempted access has been logged.',
    },
    'VALID-LOGIN-BRANDCONFLICT': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'We value your privacy. This request has been blocked and the attempted access has been logged.',
    },
    'MEDICARE-MEMBER': {
      comment: '',
      heading: 'APP NOT AVAILABLE FOR MEDICARE',
      message:
        "We're sorry, but the app isn't available for your plan type yet.",
    },
    'BOXED-OUT-WRONG-APP': {
      comment:
        'This one is miss-leading, it comes from the service when the region is not available yet. ' +
        'Such as DE or WV, so the message should be the coming soon one instead of wrong app.',
      heading: 'Oops! Something went wrong!',
      message: "We're sorry, but the app isn't available in your region yet.",
    },
    'BOXOUT-GROUP-MEMBER': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but the app isn't available for your plan type yet.",
    },
    'BOXED-OUT-NO-MEDICAL': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but the app isn't available for your plan type yet.",
    },
    'BOXED-OUT-INCORRECT-APP': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        "We're sorry, but it looks like you've downloaded the wrong app.",
    },
    'SYSTEM-ERROR': {
      comment: '',
      heading: 'Oops! Something went wrong!',
      message:
        'System error. Unable to complete request. Please try again later.',
    },
    'CSD-SUBMITTAL-SYSTEM-DOWN': {
      comment: '',
      heading: '',
      message:
        'SYSTEM DOWN: We are currently unable to accept your submittal. Please call for further assistance: 1-877-298-3918.',
    },
  },
};
