export const amhEnvironment = {
  production: true,
  disableLogs: true,
  baseRegion: 'www',
  productName: 'allmyhealth',
  cdn: {
    project: 'https://cdn.highmark.com/web/',
    base: 'https://cdn.highmark.com/',
  },
  serviceDomains: {
    dp: 'services.allmyhealth.com',
    oam: 'oauth.hmhs.com',
    login: 'auth.allmyhealth.com',
    cdsso: 'cdsso.highmark.com',
    gcp: 'prod.api.highmark.com',
    axway: 'api.hmhs.com',
    protocol: 'https://',
    experienceLayer: 'api.highmark.com',
  },
  oauth2Config: {
    env: 'auth',
    otp_username: 'appmfarest01',
    otp_password: 's4ndb0x1',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCGvyjSd-MibxYgChnhwUkkSU-8Y-cp7TY',
    authDomain: 'hhp-diginn-api-prod-243413.firebaseapp.com',
    databaseURL: 'https://hhp-diginn-api-prod-243413.firebaseio.com',
    projectId: 'hhp-diginn-api-prod-243413',
    storageBucket: 'hhp-diginn-api-prod-243413.appspot.com',
    messagingSenderId: '397796981812',
    appId: '1:397796981812:web:183f7d694bf5111de39e61',
    measurementId: 'G-ZLTJGFPCWM',
  },
  platforms: [
    {
      domain: 'https://allmyhealth.com',
      platform: 'web',
      appId: 'amh',
      title: 'AllMyHealth',
    },
    {
      domain: 'https://member.allmyhealth.com',
      platform: 'web',
      appId: 'amh',
      title: 'AllMyHealth',
    },
    {
      domain: 'https://dev.member.allmyhealth.com',
      platform: 'web',
      appId: 'amh',
      title: 'AllMyHealth',
    },
    {
      domain: 'https://ios.allmyhealth.com',
      platform: 'ios',
      appId: 'amh',
      title: 'AllMyHealth',
    },
    {
      domain: 'https://android.allmyhealth.com',
      platform: 'android',
      appId: 'amh',
      title: 'AllMyHealth',
    },
    {
      domain: 'https://csportalutilities.hmhs.com',
      platform: 'web',
      appId: 'csportal',
      title: 'csportal',
    },
  ],
  redirectWhitelist: [
    'https://dev.myhighmarkonline.com/sign-in',
    'https://dev.myhighmark.com/sign-in',
    'https://dev.beneficity.com/sign-in',
    'https://beneficity.com/sign-in',
    'https://myhighmarkonline.com/sign-in',
    'https://myhighmark.com/',
    'https://myhighmark.com/sign-in',
    'https://enroll.lark.com/',
    'league://accounts.beneficity.com/android/com.highmark.beneficity/callback',
    'com.highmark.beneficity://accounts.beneficity.com/ios/com.highmark.beneficity/callback',
    'league://accounts.myhighmarkonline.com/android/com.highmark.myhighmark/callback',
    'league://accounts.myhighmark.com/android/com.highmark.myhighmark/callback',
    'com.highmark.myhighmark://accounts.myhighmarkonline.com/ios/com.highmark.myhighmark/callback',
    'com.highmark.myhighmark://accounts.myhighmark.com/ios/com.highmark.myhighmark/callback',
    'life.league.HighmarkBeneficity://accounts.beneficity.com/ios/life.league.HighmarkBeneficity/callback',
    'league://accounts.beneficity.com/android/life.league.beneficity/callback',
    'life.league.highmark-iel://accounts.myhighmarkonline.com/ios/life.league.highmark-iel/callback',
    'life.league.highmark-iel://accounts.myhighmark.com/ios/life.league.highmark-iel/callback',
    'league://accounts.myhighmarkonline.com/android/life.league.iel/callback',
    'league://accounts.myhighmark.com/android/life.league.iel/callback',
    'https://dev.member.allmyhealth.com',
    'https://dev.member.allmyhealth.com/sign-in',
    'https://member.allmyhealth.com/sign-in',
    'https://allmyhealth.league.dev/sign-in',
    'com.highmark.allmyhealth://accounts.allmyhealth.com/ios/com.highmark.allmyhealth/callback',
    'league://accounts.allmyhealth.com/android/com.highmark.allmyhealth/callback',
  ],
  ssoConfig: {
    expressScriptUrl: `MEDCOPPB`,
    larkUrl:
      'https://enroll.lark.com/allmyhealthsso?utm_source=hm&utm_medium=partner_sso',
    hmLarkUrl: '',
    beneLarkUrl: '',
    amwellProviderId: 'https://Well360VirtualHealth.com/',
    amwellRegisterUrl: 'https://well360virtualhealth.com',
    amwellConvergeUrl: '',
    springHealthUrl:
      'https://auth.allmyhealth.com/oauth2/rest/authz?domain=SpringHealthAllMyHealth&response_type=code' +
      '&client_id=5656747-0714-a744-c30635a4656782f4b473078494e773d3d&scope=resource.READ openid' +
      '&redirect_uri=https://auth.api.springhealth.com/oauth/sso/all-my-health',
    hmSpringHealthUrl: '',
    beneSpringHealthUrl: '',
    kyruusProviderId: 'https://findcare.app/auth/realms/Highmark',
    hmFitOnUrl: '',
  },
  mtmAppId: 'IEL',
  smsTextTermsUrl: 'https://member.allmyhealth.com/SMS.pdf',
};
