import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieUtilService } from '@beneficity/shared/util';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
      private router: Router,
      private cookieUtil: CookieUtilService,
    ) {}

  /**
   * Handles 401 errors globally
   * 
   * Any 401 error will be sent to the unauthorized page. but other HttpErrorResponse types 
   * will be handled by at the page or feature component level. 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
    catchError( (err) => {

        if (err instanceof HttpErrorResponse && err.status === 401) {
           /**
             * If the storage access api is disabled by the browser && the cookie string is empty
             * We assume cookies are blocked and provide messaging with suggestions for enabling cookies.
             * lookin at you chrome...
             */
          if(!this.cookieUtil.storageAccessEnabledBrowser() && !this.cookieUtil.getCookieString()){
            this.router.navigateByUrl('unavailable')
          } else {
            this.router.navigate(['/unauthorized'], {
              state: { currentPath: window?.location?.pathname },
            });
          }
        }
        return throwError(err)
    })
    
    );
  }
}
