import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  CDN_CONFIG,
  CdnPipeModule,
  WINDOW_PROVIDERS,
  WINDOW,
} from '@atlas/ng-core';
import { AppLoadService } from './services/app-load.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { messageConfig } from './message-config.model';
import { MESSAGE_TOKEN } from '@beneficity/custom-alert/types';
import { ENV_CONFIG } from '@beneficity/environment/types';
import { ErrorInterceptor } from '@beneficity/shared/data-access';
import { amhEnvironment } from '../../environments/environment-amh';

export function initApplication(appLoadService: AppLoadService) {
  return () => appLoadService.initApp();
}

@NgModule({
  imports: [HttpClientModule, CdnPipeModule],
})
export class CoreModule {
  /**
   * Prevent re-import of the CoreModule; only the root module should import CoreModule.
   * @param {CoreModule} parentModule
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CsrCoreModule has already been loaded. Import CsrCoreModule module in AppModule only.`
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        WINDOW_PROVIDERS,
        { provide: CDN_CONFIG, useValue: environment.cdn },
        {
          provide: APP_INITIALIZER,
          useFactory: initApplication,
          deps: [AppLoadService],
          multi: true,
        },
        { provide: MESSAGE_TOKEN, useValue: messageConfig },
        { provide: ENV_CONFIG, useFactory: envFactory, deps: [] },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      ],
    };
  }
}

//
const envFactory = () => {
  let env = environment;
  if (window.location.href.includes('allmyhealth')) {
    env = amhEnvironment;
  }
  return env;
};
