import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@beneficity/authentication/data-access';

const routes: Routes = [
  {
    path: '404',
    loadChildren: () =>
      import('./pages/error/error.module').then((value) => value.ErrorModule),
    data: { title: '404' },
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error.module').then((value) => value.ErrorModule),
    data: { title: 'Error' },
  },
  {
    path: 'unavailable',
    loadChildren: () =>
      import('./pages/error/error.module').then((value) => value.ErrorModule),
    data: { title: 'Unavailable' },
  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./pages/error/error.module').then((value) => value.ErrorModule),
    data: { title: 'Unauthorized' },
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('@beneficity/authentication/feature').then(
        (value) => value.LogoutModule
      ),
    data: { title: 'Logout' },
  },
  {
    path: 'cdssologout',
    loadChildren: () =>
      import('@beneficity/authentication/feature').then(
        (value) => value.LogoutModule
      ),
    data: { title: 'Logout' },
  },
  {
    path: 'ready',
    loadChildren: () =>
      import('@beneficity/authentication/ui').then(
        (value) => value.ReadyModule
      ),
    data: { title: 'Ready' },
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'page-a',
        loadChildren: () =>
          import('./pages/placeholder/placeholder.module').then(
            (value) => value.PlaceholderModule
          ),
        data: { title: 'Secure Page A' },
      },
      {
        path: 'contact-info',
        loadChildren: () =>
          import('@beneficity/contact-information/feature').then(
            (value) => value.ContactInformationFeatureModule
          ),
        data: { title: 'Contact Information' },
      },
      {
        path: 'communication-preferences',
        loadChildren: () =>
          import('@beneficity/communication-preferences/feature').then(
            (value) => value.CommunicationPreferencesFeatureModule
          ),
        data: { title: 'Communication Preferences' },
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import('@beneficity/change-password/feature').then(
            (value) => value.ChangePasswordFeatureModule
          ),
        data: { title: 'Change Password' },
      },
      {
        path: 'mfa-management',
        loadChildren: () =>
          import('@beneficity/mfa/feature').then(
            (value) => value.MfaFeatureModule
          ),
      },
      {
        path: 'primary-care',
        loadChildren: () =>
          import('@beneficity/account-management/pcp/feature').then(
            (value) => value.PcpFeatureModule
          ),
      },
      {
        path: 'tobacco-use',
        loadChildren: () =>
          import('@beneficity/account-management/tobacco-use/feature').then(
            (value) => value.TobaccoUseFeatureModule
          ),
      },
      {
        path: 'account-management',
        loadChildren: () =>
          import('@beneficity/account-management/account-links/feature').then(
            (value) => value.AccountManagementAccountLinksFeatureModule
          ),
        data: { title: 'Account Management' },
      },
      {
        path: 'partner/:id',
        loadChildren: () =>
          import('@beneficity/authentication/feature').then(
            (value) => value.PartnerSsoModule
          ),
        data: { title: 'Partner SSO' },
      },
      {
        path: 'symptom-checker',
        loadChildren: () =>
          import('@beneficity/symptom-checker/feature').then(
            (value) => value.SymptomCheckerFeatureModule
          ),
        data: { title: 'Symptom Checker' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
