import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@beneficity/environment/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class LogoutService {
  private base = '';
  private path = 'rbsmbl/x-services/userauth/v1/login';

  constructor(
    @Inject(ENV_CONFIG) private readonly env: EnvConfig,
    private readonly httpClient: HttpClient
  ) {
    this.base = `https://${this.env.serviceDomains.dp}/`;
  }

  logout() {
    const url = `${this.base}${this.path}`;
    const headers = new HttpHeaders({ 'Mtm-Appid': this.env.mtmAppId });
    return this.httpClient.delete(url, { headers });
  }

  // Logout for the user's branded domain...
  // Clears cookies on branded domain if they get copied there for SSO such as E-bill.
  logoutByDomain(domain: string) {
    domain = domain || 'highmarkbcbs.com';
    const url = `https://${this.env.baseRegion}.${domain}/${this.path}`;
    const headers = new HttpHeaders({ 'Mtm-Appid': this.env.mtmAppId });
    return this.httpClient.delete(url, { headers });
  }
}
