import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceProperties } from '@beneficity/shared/types';
import { DPHttpClient } from '@beneficity/shared/util';

@Injectable()
export class LoginCookiesService {
  serviceProperties: ServiceProperties;

  constructor(private readonly http: DPHttpClient) {
    this.serviceProperties = {
      url: '/d20mdr/x-services/public/dig/loginCookies/setCookies',
    };
  }

  setCookies(cookieList: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const payload = {
      baseMessage: {},
      payload: cookieList,
    };
    return this.http
      .post(this.serviceProperties, payload)
      .pipe(catchError(() => of(null)));
  }
}
