import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { amhEnvironment } from './environments/environment-amh';
import { noop } from 'rxjs';

if (environment.production || amhEnvironment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (environment.disableLogs) {
  console.log = function () {
    noop();
  };
}

if (amhEnvironment.disableLogs) {
  console.log = function () {
    noop();
  };
}
