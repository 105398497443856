import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FirebaseState } from './firebase.models';
import { FIREBASE_FEATURE_KEY } from './firebase.reducer';

export const getFirebaseState = createFeatureSelector(FIREBASE_FEATURE_KEY);

export const firebaseState = createSelector(
  getFirebaseState,
  (state: FirebaseState) => state?.fireBase
);

export const fireBaseError = createSelector(
  getFirebaseState,
  (state: FirebaseState) => state?.fireBase?.error
);

export const isAuthenticated = createSelector(
  getFirebaseState,
  (state: FirebaseState) => state?.fireBase?.isAuthenticated
);
