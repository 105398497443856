import { Inject, Injectable } from '@angular/core';
import { BaseRestService, ServiceProperties } from '@beneficity/shared/types';
import { Observable } from 'rxjs';
import { DigInnApiHttpClient } from '@beneficity/shared/util';
import { ENV_CONFIG, EnvConfig } from '@beneficity/environment/types';

@Injectable()
export class FirebaseAuthTokenService implements BaseRestService {
  serviceProperties: ServiceProperties;
  constructor(
    private http: DigInnApiHttpClient,
    @Inject(ENV_CONFIG) private readonly env: EnvConfig
  ) {
    this.serviceProperties = { url: '/core/v1/token/new' };
  }

  public getNewToken(
    ltapToken: string,
    accessToken: string,
    brand: string
  ): Observable<any> {
    const request = {
      ltpaToken: ltapToken,
      accessToken: accessToken,
      brand: brand,
    };
    if (this.env?.firebaseAuthRegion) {
      request['region'] = this.env.firebaseAuthRegion;
    }

    return this.http.post(this.serviceProperties, request);
  }
}
