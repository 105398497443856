import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@atlas/ng-core';

@Injectable({
  providedIn: 'root',
})
export class DownloadUtilityService {
  constructor(@Inject(WINDOW) private window: Window) {}
  public options = 'location=no,closebuttoncaption=Close';

  public downloadFile(url): void {
    this.window.open(url, '_blank');
  }

  public downloadFileSameTab(url): void {
    this.window.location.href = url;
  }
}
