import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';
import { AuthenticationFacade } from './+state/authentication.facade';
@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private readonly authFacade: AuthenticationFacade) {}

  public static timeoutWhen<T>(value: number): OperatorFunction<T, T> {
    return function (source: Observable<T>): Observable<T> {
      return source.pipe(timeout(value));
    };
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuthState();
  }

  /**
   * Check to ensure that we have an access token
   * before activating route. Will wait for Access Token to
   * be available on state up to the login timeout (env.loginTimeout).
   * @private
   */
  private checkAuthState(): Observable<boolean> {
    return this.authFacade.isAuthenticated$.pipe(
      // Filter out results where we don't have a access token yet...
      filter((state) => {
        return state === true;
      }),
      // take only one
      take(1)
    );
  }
}
