/**
 * Interface for the 'Device' data
 */

import { PlatformInfo } from '@beneficity/shared/types';

export interface DeviceState {
  device: PlatformInfo;
  error?: any;
}

export const initialState: DeviceState = {
  // set initial required properties
  device: null,
};
