import { Action, createReducer, on } from '@ngrx/store';

import * as AuthenticationActions from './authentication.actions';
import { AuthState, initialState } from './authentication.models';

export const AUTHENTICATION_FEATURE_KEY = 'authentication';

export interface AuthenticationPartialState {
  readonly [AUTHENTICATION_FEATURE_KEY]: AuthState;
}

const authenticationReducer = createReducer(
  initialState,
  on(AuthenticationActions.authenticate, (state, action) => ({
    ...state,
    isAuthenticated: false,
    accessToken: action.accessToken,
    error: undefined,
    logoutAttempted: false,
    origin: action.origin,
  })),
  on(AuthenticationActions.logout, (state) => ({
    ...state,
    error: undefined,
    logoutAttempted: false,
  })),
  on(AuthenticationActions.authenticateSuccess, (state, action) => ({
    ...state,
    accessToken: action.accessToken,
    user: action.user,
    isAuthenticated: true,
    attributes: action.attributes,
    error: undefined,
    cookies: action.cookies,
  })),
  on(AuthenticationActions.authenticateFailure, (state, action) => ({
    ...state,
    ...initialState,
    accessToken: state?.accessToken,
    error: action.error,
  })),
  on(AuthenticationActions.logoutSuccess, (state) => ({
    ...state,
    ...initialState,
    logoutAttempted: true,
  })),
  /**
   * When logout fails, we still want to flush the state, and capture the error
   */
  on(AuthenticationActions.logoutFailure, (state, action) => ({
    ...state,
    ...initialState,
    error: action.error,
    logoutAttempted: true,
  })),
  on(AuthenticationActions.oamSessionRefresh, (state, action) => ({
    ...state,
    oamRefreshAttempted: false,
  })),
  on(
    AuthenticationActions.oamSessionRefreshSuccess,
    AuthenticationActions.oamSessionRefreshFailure,
    (state, action) => ({
      ...state,
      oamRefreshAttempted: true,
    })
  ),
  on(AuthenticationActions.updateCookiesSuccess, (state, action) => ({
    ...state,
    cookies: {...state.cookies, ...action.cookies},
  })),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authenticationReducer(state, action);
}
