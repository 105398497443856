export function isWindowCrossOrigin(window) {
  let isCrossOrigin = false;
  try {
    !!Boolean(window?.name);
  } catch (e) {
    isCrossOrigin = true;
  }
  return isCrossOrigin;
}

export function findParentWindow() {
  return {
    parent: isWindowCrossOrigin(window?.parent),
    opener: isWindowCrossOrigin(window?.opener),
  };
}

export function postMessageToWindow(
  data: Record<string, unknown>,
  win: any,
  domain: string
) {
  try {
    win?.postMessage(data, domain);
  } catch (e) {}
}

export async function requestStorageAccess(): Promise<boolean> {
  return new Promise((resolve) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      document.requestStorageAccess().then(
        () => {
          console.log('Storage access was granted');
          resolve(true);
        },
        () => {
          console.log('Storage access was denied');
          resolve(false);
        }
      );
    } catch (error) {
      // Resolve false for browsers without the storage access api enabled 
      console.log('Storage access unavailable', {error});
      resolve(false);
    }
  });
}

export function hasStorageAccess(): Promise<boolean> {
  return new Promise((resolve) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      document?.hasStorageAccess()?.then((hasAccess) => {
        console.log('Has Storage Access: ', hasAccess);
        resolve(hasAccess);
      });
    } catch (e) {
      resolve(false);
    }
  });
}

export function inFrame(): boolean {
  return window?.location !== window?.parent?.location;
}
