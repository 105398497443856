import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieUtilService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform
  ) {}

  private static getCookieRegExp(name: string): RegExp {
    // eslint-disable-next-line no-useless-escape
    const escapedName: string = name.replace(
      /([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi,
      '\\$1'
    );
    return new RegExp(
      '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)',
      'g'
    );
  }

  public exists(name: string): boolean {
    name = encodeURIComponent(name);
    const regExp = CookieUtilService.getCookieRegExp(name);
    return regExp.test(this.document.cookie);
  }

  public getCookieString(): string {
    return this.document.cookie
  }

  /**
   * Checks for browsers with storage Access API enabled. (Necessary for accessing cookies)   
   */  
  public storageAccessEnabledBrowser(): boolean {
    // Including all browsers with storage access enabled. 
    // Added extra check for Edge as Chrome and Edge both use Blink rendering engine (Chromium). 
    return (
      this.platform.EDGE ||
      this.platform.FIREFOX ||
      this.platform.SAFARI ||
      (this.platform.BLINK && navigator.userAgent.includes('Edg'))
    )
  }
}
