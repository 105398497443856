import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFirebase from './+state/firebase.reducer';
import { FirebaseEffects } from './+state/firebase.effects';
import { FirebaseFacade } from './+state/firebase.facade';
import { FirebaseAuthTokenService } from './services/firebase-auth-token.service';
import { FirebaseAuthenticationService } from './services/firebase-authentication.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFirebase.FIREBASE_FEATURE_KEY,
      fromFirebase.reducer
    ),
    EffectsModule.forFeature([FirebaseEffects]),
  ],
  providers: [
    FirebaseAuthTokenService,
    FirebaseAuthenticationService,
    FirebaseFacade,
  ],
})
export class FirebaseDataAccessModule {}
