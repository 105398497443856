import { createReducer, on, Action } from '@ngrx/store';

import * as DeviceActions from './device.actions';
import { DeviceState, initialState } from './device.models';

export const DEVICE_FEATURE_KEY = 'device';

export interface DevicePartialState {
  readonly [DEVICE_FEATURE_KEY]: DeviceState;
}

const deviceReducer = createReducer(
  initialState,
  on(DeviceActions.loadDeviceSuccess, (state, action) => ({
    ...state,
    device: action.device,
  })),
  on(DeviceActions.loadDeviceFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function reducer(state: DeviceState | undefined, action: Action) {
  return deviceReducer(state, action);
}
