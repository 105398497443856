import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Brand } from '@beneficity/brand/types';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FirebaseAuthTokenService } from './firebase-auth-token.service';
import { decodeToken, parseCookieValue } from '@beneficity/shared/util';

@Injectable()
export class FirebaseAuthenticationService {
  constructor(
    private afa: AngularFireAuth,
    private authTokenService: FirebaseAuthTokenService
  ) {}

  /**
   * Call SignIn with Custom Token generated from LtpaToken
   * to authenticate to Firebase API project
   */
  public loginFirebase(
    attributes: Record<string, unknown>,
    accessToken: string,
    brand: Brand
  ): Observable<{ correlationId: string }> {
    const ltpaToken = parseCookieValue(
      decodeToken(attributes?.['LtpaToken2'] as string)
    );
    const brandCode = brand?.umsBrandCode
      ? brand.umsBrandCode
      : brand.sharedBrandCode;

    return this.authTokenService
      .getNewToken(ltpaToken, accessToken, brandCode)
      .pipe(
        switchMap((response) => {
          return forkJoin([
            of(response),
            from(this.afa.signInWithCustomToken(response?.token)),
          ]);
        }),
        map(([tokenNewResponse, signinResponse]) => {
          return {
            correlationId: tokenNewResponse?.['x-correlation-id'],
          };
        })
      );
  }

  /**
   * Sign user out from Firebase
   */
  public logoutFirebase(): Observable<void> {
    return from(this.afa.signOut());
  }
}
