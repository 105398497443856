import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuthentication from './+state/authentication.reducer';
import { AuthenticationEffects } from './+state/authentication.effects';
import { AuthenticationFacade } from './+state/authentication.facade';
import { LoginCookiesService } from './services/api/login-cookie.service';
import { MemberAccessibleService } from './services/api/member-accessible.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthenticationGuard } from './authentication.guard';
import { OamTokenAuthService } from './services/api/oam-token-auth.service';
import { LogoutService } from './services/api/logout.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromAuthentication.AUTHENTICATION_FEATURE_KEY,
      fromAuthentication.reducer
    ),
    EffectsModule.forFeature([AuthenticationEffects]),
  ],
  providers: [
    AuthenticationFacade,
    LoginCookiesService,
    MemberAccessibleService,
    AuthenticationService,
    AuthenticationGuard,
    OamTokenAuthService,
    LogoutService,
  ],
})
export class AuthenticationDataAccessModule {}
