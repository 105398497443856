import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ddMMyyyyStrToDate',
})

// Common Date string format 1/1/1970 (Common on Member Info) to date obj
export class ddMMyyyyStrToDate implements PipeTransform {
  transform(dobStr: string): Date {
    const dobArr: Array<string> = dobStr.split('/');
    const dob: { dd: number; mm: number; yyyy: number } = {
      dd: parseInt(dobArr[0]),
      mm: parseInt(dobArr[1]) - 1, // month is 0 indexed.
      yyyy: parseInt(dobArr[2]),
    };
    return new Date(dob.yyyy, dob.mm, dob.dd);
  }
}
