import { Injectable } from '@angular/core';
import { BaseRestService, ServiceProperties } from '@beneficity/shared/types';
import { DPHttpClient } from '@beneficity/shared/util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService implements BaseRestService {
  serviceProperties: ServiceProperties;
  constructor(private http: DPHttpClient) {
    this.serviceProperties = {
      url: '/rbsmbl/x-services/singleSignon/v1/prescriptionServices',
    };
  }

  getSSOPayload(
    brand: string,
    payloadInfo: any
  ): Observable<IPrescriptionServiceResponse> {
    const headers = {
      APPID: 'CHMHOM',
      brand: brand.toUpperCase(), // uppercase required by API
    };

    const payload = {
      payload: {
        umi: payloadInfo?.umi,
        clientid: payloadInfo?.clientNumber,
        vendorName: payloadInfo?.vendorName,
        directPaySeniorMember: payloadInfo?.dpsm,
        firstName: payloadInfo?.member?.contact?.firstName,
        lastName: payloadInfo?.member?.contact?.lastName,
        dateOfBirth: payloadInfo?.member?.contact?.birthDate,
        gender: payloadInfo?.member?.gender,
        relationshipCode: payloadInfo?.member?.relationshipCode,
        relationship: payloadInfo?.member?.relationship,
        targetType: 'locatePharmacy',
      },
    };

    return this.http.post(this.serviceProperties, payload, headers);
  }
}

export interface IPrescriptionServiceResponse {
  payload: IPrescriptionServiceData;
}

export interface IPrescriptionServiceData {
  ssoUrl: string;
  ssoRequestType: string;
  ssoFormParams: [
    {
      name: string;
      value: string;
    }
  ];
}
