import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@beneficity/environment/types';
import { BaseHttpClient } from '../base-http-client.service';

/**
 * Experience Layer Secure Http Client
 */
@Injectable({
  providedIn: 'root',
})
export class ExpLayerHttpClientService extends BaseHttpClient {
  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) public envConfig: EnvConfig
  ) {
    super(http, envConfig);
    this.serverURL = `${this.envConfig.serviceDomains.protocol}${this.envConfig.serviceDomains?.experienceLayer}`;
    this.withCreds = false;
  }

  appendBaseHeaders(headers: Record<string, string>) {
    return super.appendBaseHeaders(headers);
  }
}
