import { createAction, props } from '@ngrx/store';
import { CookieList, IUser } from '@beneficity/authentication/types';
import { Brand } from '@beneficity/brand/types';

export const authenticate = createAction(
  '[Auth] Login Attempt',
  props<{
    accessToken: string;
    origin: string;
    defaultOverrideValues: Record<string, string>;
  }>()
);

export const authenticateSuccess = createAction(
  '[Auth] Login Success',
  props<{
    user: IUser;
    accessToken: string;
    attributes: Record<string, unknown>;
    brand?: Brand;
    cookies: CookieList;
  }>()
);

export const authenticateFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const logout = createAction(
  '[Auth] Logout Attempt',
  props<{ domain: any }>()
);

export const logoutSuccess = createAction('[Auth] Logout Success');

export const logoutFailure = createAction(
  '[Auth] Logout Failure',
  props<{ error: any }>()
);

export const oamSessionRefresh = createAction('[Auth] Refresh OAM Session');

export const oamSessionRefreshSuccess = createAction(
  '[Auth] Refresh OAM Session Success'
);

export const oamSessionRefreshFailure = createAction(
  '[Auth] Refresh OAM Session Failure',
  props<{ error: any }>()
);

export const updateCookies = createAction(
  '[Auth] Update Cookies Attempt',
  props<{ cookies: Record<string, string> }>()
);

export const updateCookiesSuccess = createAction(
  '[Auth] Update Cookies Success',
  props<{ cookies: Record<string, string> }>()
);

export const updateCookiesFailure = createAction(
  '[Auth] Update Cookies Failure',
  props<{ error: any }>()
);
