import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconService {
  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  public registerIcons() {
    this.iconRegistry.addSvgIcon(
      'hm-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/hm-logo.svg`)
    );
    this.iconRegistry.addSvgIcon(
      'go-paperless-single-leaf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/go-paperless-icon-single-leaf.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-close',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg')
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-done',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/done.svg')
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-info-outline',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/info_outline.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'mma-error-outline',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/error_outline-24px.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-info',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/info.svg')
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-error',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/error.svg')
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-warning',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/warning.svg')
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-warning-outline',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/warning_outline.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-success',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/check_circle.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-download',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/download.svg')
    );

    this.iconRegistry.addSvgIcon(
      'matlocal-chevron-right',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/chevron_right.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'matlocal-chevron-down',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/chevron_down.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'matlocal-arrow-back',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/arrow_back.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-clear',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clear.svg')
    );
    this.iconRegistry.addSvgIcon(
      'need-help',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/need_help.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'mma-delete',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/delete-large.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'mma-edit',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg')
    );
    this.iconRegistry.addSvgIcon(
      'matlocal-cancel-filled',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/cancel_filled.svg'
      )
    );
  }
}
