import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import * as FirebaseSelectors from './firebase.selectors';

@Injectable()
export class FirebaseFacade {
  firebase$ = this.store.pipe(select(FirebaseSelectors.firebaseState));
  firebaseAuthenticated$ = this.store.pipe(
    select(FirebaseSelectors.isAuthenticated)
  );
  firebaseError$ = this.store.pipe(select(FirebaseSelectors.fireBaseError));

  getIsAuthenticated() {
    let a;
    this.firebaseAuthenticated$.pipe(take(1)).subscribe((y) => (a = y));
    return a;
  }
  constructor(private store: Store) {}
}
