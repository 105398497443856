import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceState } from './device.models';
import { DEVICE_FEATURE_KEY, DevicePartialState } from './device.reducer';

// Lookup the 'Device' feature state managed by NgRx
export const getDeviceState = createFeatureSelector<
  DevicePartialState,
  DeviceState
>(DEVICE_FEATURE_KEY);

export const getDeviceInfo = createSelector(
  getDeviceState,
  (state: DeviceState) => state?.device
);


export const getPlatform = createSelector(
  getDeviceState,
  (state: DeviceState) => state?.device?.platform
);

export const getDeviceError = createSelector(
  getDeviceState,
  (state: DeviceState) => state.error
);
