import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthenticationDataAccessModule } from '@beneficity/authentication/data-access';
import { DeviceDataAccessModule } from '@beneficity/shared/data-access';
import { FirebaseDataAccessModule } from '@beneficity/firebase/data-access';

export const reducers: ActionReducerMap<Record<string, unknown>> = {
  router: routerReducer,
};

// /**
//  * Meta Reducer for flushing the Application State on logout
//  * @param reducer
//  */
// export function flush(reducer: ActionReducer<Record<string, unknown>>): ActionReducer<Record<string, unknown>> {
//   return function (state, action) {
//     if (
//       action &&
//       (action.type === logoutSuccess.type || action.type === logoutFailure.type || action.type === logoutGlobal.type)
//     ) {
//       return reducer(
//         {
//           router: state.router,
//           brand: {
//             brand: BrandValues.find((b) => b.ubk === environment.api.defaultUbk),
//             loaded: true,
//           },
//         },
//         action
//       );
//     }
//     return reducer(state, action);
//   };
// }

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(reducers, {
      // metaReducers: !environment.production ? [flush] : [flush],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    // StoreRouterConnectingModule.forRoot({
    //   serializer: CustomRouteSerializer,
    // }),
    StoreDevtoolsModule.instrument(),
    AuthenticationDataAccessModule,
    FirebaseDataAccessModule,
    DeviceDataAccessModule,
  ],
})
export class StateModule {}
