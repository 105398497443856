export interface IAuthenticator {
  challengeType: string;
  prompt: string;
  contact?: string;
}

// Should be used for readability purpose to evaluate string value from ReST Service.
export enum EnrollmentStatus {
  Enrolled = 'true',
  Unenrolled = 'false',
}

export interface StepperValueConfig {
  smsNumber: string;
  email: string;
  emailConfirmedInd: string;
  verificationError?: boolean;
}

export interface StepEnablementConfig {
  deviceSelectionNext: boolean;
  deviceSelectionComplete: boolean;
  smsVerificationStep: boolean;
  emailVerificationStep: boolean;
}

export interface ConfirmAndSaveStepConfig {
  hasSmsChanged: boolean;
  hasEmailChanged: boolean;
}

export interface EnrollmentRequest {
  oamAccessToken: string;
  hmhsmfaenrolled?: EnrollmentStatus;
  hmhsmfaphone?: string;
  hmhsmfaemail?: string;
}

export interface EnrollmentResponse {
  message: string;
  status: string;
}

export interface EnrollmentResponse {
  message: string;
  status: string;
}

export enum UpdateEnrollmentStatus {
  DEFAULT = 'default',
  SUCCESS = 'success',
  FAILURE = 'failure',
}
