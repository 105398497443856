import { HttpClient } from '@angular/common/http';
import { BaseHttpClient } from '../base-http-client.service';

import { Inject, Injectable } from '@angular/core';
import { EnvConfig, ENV_CONFIG } from '@beneficity/environment/types';

/**
 * BaseExternalHttpClient should be extended by all custom clients
 * that will be injected by on prem services (GCP/AEM/etc...)
 * https://gitlabhealth.highmark.com/atlas_labs/community/diginn-docs/blob/master/resources/member-portal/rest-services/base-http-client.md
 */
@Injectable({
  providedIn: 'root',
})
export class BaseExternalHttpClient extends BaseHttpClient {
  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) public envConfig: EnvConfig
  ) {
    super(http, envConfig);
  }
}
