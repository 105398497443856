import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvConfig, ENV_CONFIG } from '@beneficity/environment/types';
import { BaseInternalHttpClient } from './base-internal-http-client.service';

/**
 * Axway Secure Http Client can be injected into any services that are exposed
 * via Axway API Gateway, and will automatically disable credentials to prevent
 * cookieString from being sent with requests.
 *
 * TJK This needs to be re-written so that the AUTH resources don't depend on it.
 * After, we can create a base header with accessToken.
 */
@Injectable({
  providedIn: 'root',
})
export class AxwayHttpClient extends BaseInternalHttpClient {
  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) public envConfig: EnvConfig
  ) {
    super(http, envConfig);
    this.serverURL = `${this.envConfig.serviceDomains.protocol}${this.envConfig.serviceDomains.axway}`;
    this.withCreds = false;
  }

  appendBaseHeaders(headers: Record<string, string>) {
    let axwayHeaders: HttpHeaders = super.appendBaseHeaders(headers);

    if (this.envConfig.serviceDomains?.wasBackend) {
      axwayHeaders = axwayHeaders.append(
        'x-hmhs-test-backend',
        this.envConfig.serviceDomains.wasBackend
      );
    }
    return axwayHeaders;
  }
}
