import { Observable, throwError } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { EnvConfig, ENV_CONFIG } from '@beneficity/environment/types';
import {
  IBaseHttpClient,
  ServiceProperties,
  ResponseType,
} from '@beneficity/shared/types';

/**
 * Base HTTP client uses Angular http by default
 * and removes inherent dependancy on separate web & app implementations.
 * This client should contain all basic functionality that is common to every REST Service
 */
export abstract class BaseHttpClient implements IBaseHttpClient {
  serverURL = '/';
  APPID = 'BENEFICITY';
  withCreds = true; // set globally, so that it can be overrided for specific httpClients (e.g. axway)

  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) protected envConfig: EnvConfig
  ) {}

  get(
    props: ServiceProperties,
    params?: { [param: string]: string | string[] },
    headers?: { [name: string]: string | string[] },
    pathParams?: { [key: string]: string },
    responseType?: ResponseType
  ): Observable<any> {
    const url = this.buildResourceURL(props, null, pathParams);
    const req = {
      headers: this.appendBaseHeaders(headers),
      params: new HttpParams({ fromObject: params }),
      withCredentials: this.withCreds
    };

    if (!!responseType) {
      req['responseType'] = responseType;
    }
    return this.http.get(url, req);
  }

  head(
    props: ServiceProperties,
    params?: { [param: string]: string | string[] },
    headers?: { [name: string]: string | string[] },
    pathParams?: { [key: string]: string }
  ): Observable<any> {
    console.warn('"Head" is not implemented for BaseHttpClient.');
    return throwError('"Head" is not implemented for BaseHttpClient.');
  }

  post(
    props: ServiceProperties,
    data: any,
    headers?: { [name: string]: string | string[] },
    params?: { [param: string]: string | string[] },
    pathParams?: { [key: string]: string },
    responseType?: string
  ): Observable<any> {
    const url = this.buildResourceURL(props, null, pathParams);

    const req = {
      headers: this.appendBaseHeaders(headers),
      params: new HttpParams({ fromObject: params }),
      withCredentials: this.withCreds
    };

    if (!!responseType) {
      req['responseType'] = responseType;
    }

    return this.http.post(
      url,
      headers && headers['Content-Type'] === 'application/x-www-form-urlencoded'
        ? this.toHttpParams(data)
        : data,
      req
    );
  }

  put(
    props: ServiceProperties,
    data: any,
    headers?: { [name: string]: string | string[] },
    pathParams?: { [key: string]: string },
    responseType?: string
  ): Observable<any> {
    const url = this.buildResourceURL(props, null, pathParams);
    const req = {
      headers: this.appendBaseHeaders(headers),
      withCredentials: this.withCreds

    };

    if (!!responseType) {
      req['responseType'] = responseType;
    }

    return this.http.put(url, data, req);
  }

  delete(
    props: ServiceProperties,
    params?: { [param: string]: string | string[] },
    headers?: { [name: string]: string | string[] },
    pathParams?: { [key: string]: string },
    responseType?: string
  ): Observable<any> {
    const url = this.buildResourceURL(props, null, pathParams);
    const req = {
      headers: this.appendBaseHeaders(headers),
      params: new HttpParams({ fromObject: params }),
      withCredentials: this.withCreds

    };

    if (!!responseType) {
      req['responseType'] = responseType;
    }
    return this.http.delete(url, req);
  }

  download(
    props: ServiceProperties,
    body: any,
    filePath: string,
    otherURL?: string
  ): Observable<any> {
    console.warn('"Download" is not implemented for BaseHttpClient.');
    return throwError('"Download" is not implemented for BaseHttpClient.');
  }

  protected appendBaseHeaders(
    inHeaders?: string | { [name: string]: string | string[] }
  ) {
    let headers = new HttpHeaders(inHeaders);
    if (!inHeaders) {
      // headers = headers.append('appid', this.APPID); // removing this line until APPID is confirmed
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Accept', 'application/json');
    }
    return headers;
  }

  protected toHttpParams(obj: { [param: string]: string }): HttpParams {
    return Object.getOwnPropertyNames(obj).reduce(
      (p, key) => p.set(key, obj[key]),
      new HttpParams()
    );
  }

  public getUrlProperty(
    props: ServiceProperties,
    pathParams?: { [key: string]: string }
  ): string {
    let url = '';
    if (typeof props.url === 'function') {
      url = props.url(this.envConfig, pathParams);
    } else {
      url = props.url;
    }
    return url;
  }

  public getResourcePath(url: string): string {
    return this.extractPath(url);
  }

  protected extractPath(url: string) {
    const path = url.split(this.serverURL);
    if (path && path[1] && path[1].indexOf('/') !== 0) {
      return '/' + path[1];
    } else if (path && path[1]) {
      return path[1];
    }
    return url;
  }

  protected buildResourceURL(
    props: ServiceProperties,
    queryParams?: any,
    pathParams?: { [key: string]: string }
  ): string {
    let url = this.getUrlProperty(props, pathParams);

    if (queryParams) {
      Object.keys(queryParams).forEach((k) => {
        const paramString = '?' + k + '=' + queryParams[k];
        url += paramString;
      });
    }

    if (url.indexOf('http') < 0) {
      url = this.serverURL + url;
    }

    return url;
  }
}
