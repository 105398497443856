import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDevice from './+state/device.reducer';
import { DeviceEffects } from './+state/device.effects';
import { DeviceFacade } from './+state/device.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDevice.DEVICE_FEATURE_KEY, fromDevice.reducer),
    EffectsModule.forFeature([DeviceEffects]),
  ],
  providers: [DeviceFacade],
})
export class DeviceDataAccessModule {}
