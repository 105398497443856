import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseHttpClient } from '../base-http-client.service';
import { Inject, Injectable } from '@angular/core';
import { EnvConfig, ENV_CONFIG } from '@beneficity/environment/types';

/**
 * BaseInternalHttpClient should be extended by all custom clients
 * that will be injected by on prem services (D20/RBS/etc...)
 * https://gitlabhealth.highmark.com/atlas_labs/community/diginn-docs/blob/master/resources/member-portal/rest-services/base-http-client.md
 */
@Injectable({
  providedIn: 'root',
})
export class BaseInternalHttpClient extends BaseHttpClient {
  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) public envConfig: EnvConfig
  ) {
    super(http, envConfig);
  }

  appendBaseHeaders(headers: any) {
    let internalResourceHeaders: HttpHeaders = super.appendBaseHeaders(
      headers
    );
    internalResourceHeaders = internalResourceHeaders.append('Mtm-Appid', this.envConfig?.mtmAppId);
    return internalResourceHeaders;
  }
}
