/* eslint-disable no-dupe-else-if */

import {
  DEFAULT_VIEWPORT,
  L_PX_MAX,
  LARGE,
  M_PX_MAX,
  MEDIUM,
  S_PX_MAX,
  SMALL,
  X_LARGE,
} from '@beneficity/shared/types';
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  protected size: string;

  constructor(private platform: Platform) {}

  public getSize(): string {
    const viewportSize = this.identifySize();
    return viewportSize || DEFAULT_VIEWPORT;
  }

  identifySize(): string {
    if (this.platform.ANDROID) {
      return this.nativeSizeCheck();
    } else if (this.platform.IOS || this.iPadCheck()) {
      return this.nativeSizeCheck();
    } else if (this.platform.WEBKIT && this.iPadCheck()) {
      return this.nativeSizeCheck();
    } else if (this.platform.EDGE) {
      return this.browserSizeCheck();
    } else if (this.platform.FIREFOX) {
      return this.browserSizeCheck();
    } else if (this.platform.SAFARI) {
      return this.browserSizeCheck();
    } else if (this.platform.isBrowser) {
      return this.browserSizeCheck();
    }
    return DEFAULT_VIEWPORT;
  }

  private nativeSizeCheck(): string {
    const width = window.innerWidth;

    if (width <= S_PX_MAX) {
      return SMALL;
    } else if (width > S_PX_MAX && width <= M_PX_MAX) {
      return MEDIUM;
    } else {
      return DEFAULT_VIEWPORT;
    }
  }

  private browserSizeCheck() {
    const width = window.innerWidth;

    if (width > L_PX_MAX) {
      return X_LARGE;
    } else {
      return LARGE;
    }
  }

  // With the release of iOS 13, Safari on iPad now has "Request Desktop Website" enabled by default.
  // This means the iPad will no longer identify itself as an iPad in the user agent by default.
  // Currently we are able to work around this by checking if the platform is a Mac and supports multiple touches.
  // Mac OS desktop/laptops currently do not support multiple touches so the device must be a iPad.
  // !!This could change in the future!!
  private iPadCheck(): boolean {
    return (
      navigator.platform.toLowerCase().match('macintel') &&
      navigator.maxTouchPoints != null &&
      navigator.maxTouchPoints > 1
    );
  }
}
