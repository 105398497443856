import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FromyyyyMMddPipe } from './fromyyyyMMdd.pipe';
import { ddMMyyyyStrToDate } from './ddMMyyyyToDate.pipe';

@NgModule({
  declarations: [FromyyyyMMddPipe, ddMMyyyyStrToDate],
  exports: [FromyyyyMMddPipe, ddMMyyyyStrToDate],
  imports: [CommonModule],
})
export class PipesModule {}
