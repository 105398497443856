import { Brand } from '@beneficity/brand/types';
import { createAction, props } from '@ngrx/store';

// FIREBASE
export const firebaseLogin = createAction(
  '[Auth] Firebase Login Attempt',
  props<{
    accessToken: string;
    attributes: Record<string, unknown>;
    brand: Brand;
  }>()
);

export const firebaseLoginSuccess = createAction(
  '[Auth] Firebase Login Success',
  props<{ correlationId: string }>()
);

export const firebaseLoginFailure = createAction(
  '[Auth] Firebase Login Failure',
  props<{ error: any }>()
);

export const firebaseLogout = createAction('[Auth] firebaseLogout Attempt');

export const firebaseLogoutSuccess = createAction(
  '[Auth] firebaseLogout Success'
);

export const firebaseLogoutFailure = createAction(
  '[Auth] firebaseLogout Failure',
  props<{ error: any }>()
);
