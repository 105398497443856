import { Injectable } from '@angular/core';
import { PlatformInfo } from '@beneficity/shared/types';

import { Action, select, Store } from '@ngrx/store';

import * as DeviceActions from './device.actions';
import * as DeviceSelectors from './device.selectors';
import { take } from 'rxjs/operators';

@Injectable()
export class DeviceFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  deviceInfo$ = this.store.pipe(select(DeviceSelectors.getDeviceInfo));
  deviceError$ = this.store.pipe(select(DeviceSelectors.getDeviceError));

  constructor(private store: Store) {}

  private dispatch(action: Action) {
    this.store.dispatch(action);
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  loadDeviceInfo(appOrigin: string): void {
    this.dispatch(DeviceActions.loadDevice({ appOrigin }));
  }

  /**
   * Get current device/platform info
   */
  getDeviceInfo(): PlatformInfo {
    let a = null;
    this.deviceInfo$.pipe(take(1)).subscribe((y) => (a = y));
    return a;
  }
}
