import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvConfig, ENV_CONFIG } from './environment.types';

const EnvTestConfig: EnvConfig = {
  production: false,
  frameTarget: 'test',
  cdn: {
    project: 'https://dev.cdn.highmark.com/web/',
    base: 'https://dev.cdn.highmark.com/',
  },
  serviceDomains: {
    dp: '',
    oam: '',
    login: '',
    cdsso: '',
    protocol: '',
    gcp: 'alpha.api.dev.highmark.com',
  },
  oauth2Config: {
    grant_type: 'password',
    client_id: 'test86753089',
    client_secret: 'test86753089',
    redirect_uri: 'local.member.highmark.com:',
    domain_name: 'string',
    env: 'string',
    otp_username: 'string',
    otp_password: 'string',
  },
  validDomains: ['local.member.highmark.com:'],
  defaultUbk: 6002,
};

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [{ provide: ENV_CONFIG, useValue: EnvTestConfig }],
})
export class EnvironmentTestingModule {}
